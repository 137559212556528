import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Upload, Save, AlertCircle, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { api } from '../../services/api';
import { getBaseUrl } from '../../services/api-config';
import type { Company } from '../../services/api';

const API_BASE = process.env.REACT_APP_API_URL?.replace('/api', '') || 'http://localhost:5002';

const CompanyEdit: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const [companyId, setCompanyId] = useState<number | null>(null);
  const [company, setCompany] = useState<Company | null>(null);
  const [name, setName] = useState<string>('');
  const [promptTemplate, setPromptTemplate] = useState<string>('');
  const [logo, setLogo] = useState<File | null>(null);
  const [logoPreview, setLogoPreview] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const getLogoUrl = (logoUrl: string | null | undefined): string => {
    if (!logoUrl) return '';
    if (logoUrl.startsWith('http')) return logoUrl;
    // Konstruiere die vollständige URL basierend auf der relativen URL
    return `${API_BASE}/static/company_logos/${logoUrl.split('/').pop()}`;
  };

  useEffect(() => {
    if (!id) {
      setError('Ungültige Unternehmens-ID.');
      setIsLoading(false);
      return;
    }

    const parsedId = parseInt(id, 10);
    if (isNaN(parsedId)) {
      setError('Ungültige Unternehmens-ID.');
      setIsLoading(false);
      return;
    }

    setCompanyId(parsedId);

    const fetchCompany = async () => {
      try {
        setIsLoading(true);
        const companyData = await api.companies.get(parsedId);

        if (!companyData) {
          throw new Error('Keine Unternehmensdaten gefunden');
        }

        setCompany(companyData);
        setName(companyData.name);
        setPromptTemplate(companyData.prompt_template || '');
        if (companyData.logo_url) {
          const logoUrl = getLogoUrl(companyData.logo_url);
          console.log('Setting logo preview URL:', logoUrl); // Debug
          setLogoPreview(logoUrl);
        }
      } catch (error) {
        console.error('Error fetching company:', error);
        setError(
          error instanceof Error
            ? error.message
            : 'Fehler beim Laden der Unternehmensdaten'
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompany();
  }, [id]);

  const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setError('Das Logo darf nicht größer als 5MB sein.');
        return;
      }

      if (!file.type.startsWith('image/')) {
        setError('Bitte wählen Sie eine gültige Bilddatei aus.');
        return;
      }

      setError(''); // Reset error
      setLogo(file);
      const previewUrl = URL.createObjectURL(file);
      setLogoPreview(previewUrl);

      return () => URL.revokeObjectURL(previewUrl);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!name || !promptTemplate) {
      setError('Bitte füllen Sie alle erforderlichen Felder aus.');
      return;
    }

    if (companyId === null) {
      setError('Ungültige Unternehmens-ID.');
      return;
    }

    try {
      setIsSaving(true);
      setError('');

      // Update company info
      await api.companies.update(companyId, {
        name,
        prompt_template: promptTemplate,
      });

      // Upload new logo if selected
      if (logo) {
        const formData = new FormData();
        formData.append('file', logo);
        try {
          const response = await api.companies.uploadLogo(companyId, formData);
          if (response.logo_url) {
            setLogoPreview(getLogoUrl(response.logo_url));
          }
        } catch (error) {
          setError('Fehler beim Upload des Logos. Bitte versuchen Sie es mit einer kleineren Datei.');
          console.error('Logo upload error:', error);
          return;
        }
      }

      navigate('/companies');
    } catch (error) {
      console.error('Error saving company:', error);
      setError(
        error instanceof Error
          ? error.message
          : 'Fehler beim Speichern der Änderungen'
      );
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeleteLogo = async () => {
    if (!company || !window.confirm('Möchten Sie das Logo wirklich löschen?')) return;

    try {
      await api.companies.deleteLogo(company.id);
      setLogoPreview('');
      setLogo(null);
    } catch (error) {
      console.error('Error deleting logo:', error);
      setError(
        error instanceof Error
          ? error.message
          : 'Fehler beim Löschen des Logos'
      );
    }
  };

  const renderLogo = () => {
    if (logoPreview) {
      return (
        <div className="relative group">
          <motion.img
            whileHover={{ scale: 1.05 }}
            src={logoPreview}
            alt={`${name} Logo`}
            className="w-20 h-20 rounded-full object-cover border-2 border-[rgb(255,138,76)]"
            onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
              console.error('Logo load error for URL:', logoPreview);
              e.currentTarget.style.display = 'none';
              setError('Logo konnte nicht geladen werden');
            }}
          />
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={handleDeleteLogo}
            className="absolute -top-2 -right-2 p-1.5 bg-red-100 rounded-full text-red-500
                     hover:bg-red-200 opacity-0 group-hover:opacity-100 transition-all duration-300"
          >
            <X size={16} />
          </motion.button>
        </div>
      );
    }

    return (
      <motion.div
        whileHover={{ scale: 1.05 }}
        className="w-20 h-20 bg-[rgb(244,245,246)] rounded-full flex items-center justify-center border-2 border-[rgb(255,138,76)]"
      >
        <span className="text-3xl font-bold text-[rgb(83,103,123)]">
          {name.charAt(0)}
        </span>
      </motion.div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: 'linear' }}
          className="rounded-full h-16 w-16 border-4 border-[rgb(255,138,76)] border-t-[rgb(58,79,102)]"
        />
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg p-8"
    >
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => navigate('/companies')}
        className="mb-8 flex items-center text-[rgb(83,103,123)] hover:text-[rgb(58,79,102)] transition-colors"
      >
        <ArrowLeft size={24} className="mr-2" />
        <span className="text-lg">Zurück zur Übersicht</span>
      </motion.button>

      <div className="flex items-center mb-8">
        {renderLogo()}
        <h1 className="ml-6 text-3xl font-bold text-[rgb(58,79,102)]">
          Unternehmensprofil bearbeiten
        </h1>
      </div>

      <AnimatePresence>
        {error && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="mb-6 p-4 bg-red-50 text-red-700 rounded-lg flex items-center"
          >
            <AlertCircle size={24} className="mr-2" />
            {error}
          </motion.div>
        )}
      </AnimatePresence>

      <form onSubmit={handleSubmit} className="space-y-8">
        <div className="space-y-2">
          <label className="block text-lg font-medium text-[rgb(58,79,102)]">
            Unternehmensname
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-[rgb(255,138,76)] bg-[rgb(244,245,246)] text-[rgb(58,79,102)]"
            placeholder="Unternehmensname eingeben"
            required
          />
        </div>

        <div className="space-y-2">
          <label className="block text-lg font-medium text-[rgb(58,79,102)]">
            Unternehmenslogo
          </label>
          <div className="flex items-center space-x-4">
            <motion.label
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="flex items-center justify-center px-6 py-3 rounded-lg bg-[rgb(244,245,246)] cursor-pointer hover:bg-[rgb(83,103,123)] hover:text-white transition-colors"
            >
              <Upload size={24} className="mr-2" />
              <span className="text-lg">Logo auswählen</span>
              <input
                type="file"
                onChange={handleLogoChange}
                accept="image/*"
                className="hidden"
              />
            </motion.label>
            {logo && (
              <span className="text-lg text-[rgb(83,103,123)]">
                Ausgewählt: {logo.name}
              </span>
            )}
          </div>
        </div>

        <div className="space-y-2">
          <label className="block text-lg font-medium text-[rgb(58,79,102)]">
            Prompt Template
          </label>
          <textarea
            value={promptTemplate}
            onChange={(e) => setPromptTemplate(e.target.value)}
            className="w-full p-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-[rgb(255,138,76)] bg-[rgb(244,245,246)] h-64 font-mono text-[rgb(58,79,102)]"
            placeholder="Prompt Template eingeben"
            required
          />
        </div>

        <div className="flex justify-between pt-8">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            type="button"
            onClick={() => navigate('/companies')}
            className="px-6 py-3 rounded-lg text-[rgb(83,103,123)] hover:bg-[rgb(244,245,246)] transition-colors text-lg"
          >
            Abbrechen
          </motion.button>

          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            type="submit"
            disabled={isSaving}
            className={`px-6 py-3 rounded-lg bg-[rgb(255,138,76)] text-white hover:bg-[rgb(224,121,66)] transition-colors flex items-center space-x-2 text-lg ${
              isSaving ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            <Save size={24} />
            <span>{isSaving ? 'Wird gespeichert...' : 'Änderungen speichern'}</span>
          </motion.button>
        </div>
      </form>
    </motion.div>
  );
};

export default CompanyEdit;