import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

// Type Definitions
interface User {
    id: number;
    username: string;
    email?: string;
    role: string;
    is_active: boolean;
    created_at: string;
    updated_at: string | null;
    profile_image_url: string | null;
}

interface LoginResponse {
    access_token: string;
    id: number;
    username: string;
    email: string;
    role: string;
}

interface AuthState {
    user: User | null;
    token: string | null;
}

interface AuthContextType extends AuthState {
    isAuthenticated: boolean;
    isLoading: boolean;
    error: string | null;
    login: (username: string, password: string) => Promise<void>;
    logout: () => void;
}

// API Configuration
const API_BASE = process.env.REACT_APP_API_URL || 'http://localhost:5002/api';

// Context Creation
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Provider Component
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    // State Management
    const [authState, setAuthState] = useState<AuthState>(() => {
        const token = localStorage.getItem('auth_token');
        const userStr = localStorage.getItem('user_data');
        return {
            token,
            user: userStr ? JSON.parse(userStr) : null,
        };
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    // Logout Function with useCallback
    const logout = useCallback(() => {
        localStorage.removeItem('auth_token');
        localStorage.removeItem('user_data');
        setAuthState({ user: null, token: null });
        navigate('/login');
    }, [navigate]);

    // Token Validation
    useEffect(() => {
        const validateToken = async () => {
            const token = localStorage.getItem('auth_token');
            if (!token) {
                return;
            }

            try {
                const response = await fetch(`${API_BASE}/auth/validate`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Token validation failed');
                }

                const data = await response.json();
                if (!data.valid) {
                    throw new Error('Token invalid');
                }
            } catch (error) {
                console.error('Token validation failed:', error);
                localStorage.removeItem('auth_token');
                localStorage.removeItem('user_data');
                setAuthState({ user: null, token: null });
                navigate('/login');
            }
        };

        validateToken();
    }, [navigate]);

    // Login Function
    const login = async (username: string, password: string) => {
        setIsLoading(true);
        setError(null);

        try {
            const formData = new URLSearchParams();
            formData.append('username', username);
            formData.append('password', password);

            const response = await fetch(`${API_BASE}/auth/token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json'
                },
                body: formData.toString()
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(errorText || 'Login fehlgeschlagen');
            }

            const data: LoginResponse = await response.json();

            if (data.access_token) {
                localStorage.setItem('auth_token', data.access_token);
                const userData: User = {
                    id: data.id,
                    username: data.username,
                    email: data.email,
                    role: data.role,
                    is_active: true,
                    created_at: new Date().toISOString(),
                    updated_at: null,
                    profile_image_url: null
                };
                localStorage.setItem('user_data', JSON.stringify(userData));

                setAuthState({
                    token: data.access_token,
                    user: userData
                });

                navigate('/posts');
            }
        } catch (error) {
            console.error('Login error:', error);
            const errorMessage = error instanceof Error ? error.message : 'Login fehlgeschlagen';
            setError(errorMessage);
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    // Unauthorized Event Handler
    useEffect(() => {
        const handleUnauthorized = (event: MessageEvent) => {
            if (event.data === 'UNAUTHORIZED') {
                logout();
            }
        };

        window.addEventListener('message', handleUnauthorized);

        return () => {
            window.removeEventListener('message', handleUnauthorized);
        };
    }, [logout]);

    // Context Provider
    return (
        <AuthContext.Provider
            value={{
                user: authState.user,
                token: authState.token,
                isAuthenticated: !!authState.token,
                isLoading,
                error,
                login,
                logout
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

// Hook
export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export default AuthContext;