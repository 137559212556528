// src/services/api.ts

import { getBaseUrl, buildApiUrl, getAuthHeaders, ApiError } from './api-config';

// Types
interface AxiosRequestConfig {
    baseURL?: string;
    headers?: Record<string, string>;
    withCredentials?: boolean;
    method?: string;
    data?: any;
}

interface AxiosResponse<T = any> {
    data: T;
    status: number;
    statusText: string;
    headers: Record<string, string>;
    config: AxiosRequestConfig;
}

interface AxiosError {
    response?: {
        status: number;
        data: any;
    };
    message: string;
}

interface RequestInterceptorConfig extends AxiosRequestConfig {
    headers: Record<string, string>;
}

interface AxiosInstance {
    defaults: AxiosRequestConfig;
    interceptors: {
        request: {
            use(onFulfilled?: (config: RequestInterceptorConfig) => RequestInterceptorConfig | Promise<RequestInterceptorConfig>,
                onRejected?: (error: AxiosError) => any): number;
        };
        response: {
            use(onFulfilled?: (response: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>,
                onRejected?: (error: AxiosError) => any): number;
        };
    };
    get<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>;
    delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>;
    post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>;
    patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>;
    put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>;
}

interface FileUploadResponse {
    logo_url: string;
}

interface CompanyLogoUpload {
    companyId: number;
    formData: FormData;
}

// API Types
export type UserRole = 'ADMIN' | 'USER';

export interface CompanyConfigResponse {
    content: string;
    company_id: number;
    last_modified: string;
}

export interface Company {
    id: number;
    name: string;
    prompt_template: string;
    created_at: string;
    logo_url?: string;
}

export interface Post {
    id: number;
    title: string;
    content: string;
    original_content: string;
    company_id: number;
    created_at: string;
    updated_at: string | null;
    company: Company | null;
    images?: string[];
}

// Dynamic axios import
let axiosModule: any;

async function getAxios() {
    if (!axiosModule) {
        axiosModule = await import('axios');
    }
    return axiosModule.default;
}

// API Configuration
const API_BASE = getBaseUrl();

// Create axios instance
let axiosInstance: AxiosInstance;

async function createAxiosInstance() {
    if (!axiosInstance) {
        const axios = await getAxios();
        axiosInstance = axios.create({
            baseURL: API_BASE,
            headers: {
                ...getAuthHeaders(),
                'Accept': 'application/json'
            },
            withCredentials: true
        });

        // Request Interceptor
        axiosInstance.interceptors.request.use(
            (config: RequestInterceptorConfig) => {
                const token = localStorage.getItem('auth_token');
                if (token) {
                    config.headers['Authorization'] = `Bearer ${token}`;
                }
                if (!config.baseURL) {
                    config.baseURL = API_BASE;
                }
                return config;
            },
            (error: AxiosError) => {
                return Promise.reject(error);
            }
        );

        // Response Interceptor
        axiosInstance.interceptors.response.use(
            (response: AxiosResponse) => response,
            async (error: AxiosError) => {
                if (error.response?.status === 401) {
                    localStorage.removeItem('auth_token');
                    localStorage.removeItem('user_data');
                    window.location.href = '/login';
                }
                console.error('API Error:', error);
                throw new ApiError(
                    error.response?.status || 500,
                    error.response?.data?.detail || error.response?.data?.message || error.message || 'Ein Fehler ist aufgetreten',
                    error.response?.data
                );
            }
        );
    }
    return axiosInstance;
}

// API Service Implementation
export const api = {
    auth: {
        login: async (username: string, password: string) => {
            try {
                const axios = await createAxiosInstance();
                const formData = new URLSearchParams();
                formData.append('username', username);
                formData.append('password', password);

                const response = await axios.post('/auth/token', formData, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                });

                if (response.data.access_token) {
                    localStorage.setItem('auth_token', response.data.access_token);
                }

                return response.data;
            } catch (error) {
                if (error instanceof ApiError) {
                    throw error;
                }
                throw new ApiError(500, 'Login fehlgeschlagen');
            }
        },

        validate: async () => {
            try {
                const axios = await createAxiosInstance();
                const response = await axios.get('/auth/validate');
                return response.data;
            } catch (error) {
                if (error instanceof ApiError) {
                    throw error;
                }
                throw new ApiError(500, 'Token-Validierung fehlgeschlagen');
            }
        }
    },

    companies: {
        list: async (): Promise<Company[]> => {
            try {
                const axios = await createAxiosInstance();
                const response = await axios.get<Company[]>('/companies');
                return response.data;
            } catch (error) {
                if (error instanceof ApiError) {
                    throw error;
                }
                throw new ApiError(500, 'Fehler beim Laden der Unternehmen');
            }
        },

        get: async (id: number): Promise<Company> => {
            try {
                const axios = await createAxiosInstance();
                const response = await axios.get<Company>(`/companies/${id}`);
                return response.data;
            } catch (error) {
                if (error instanceof ApiError) {
                    if (error.status === 404) {
                        throw new ApiError(404, 'Unternehmen nicht gefunden');
                    }
                    throw error;
                }
                throw new ApiError(500, 'Fehler beim Laden des Unternehmens');
            }
        },

        create: async (data: { name: string; prompt_template: string }): Promise<Company> => {
            try {
                const axios = await createAxiosInstance();
                const response = await axios.post<Company>('/companies', data);
                return response.data;
            } catch (error) {
                if (error instanceof ApiError) {
                    throw error;
                }
                throw new ApiError(500, 'Fehler beim Erstellen des Unternehmens');
            }
        },

        update: async (id: number, data: Partial<Company>): Promise<Company> => {
            try {
                const axios = await createAxiosInstance();
                const response = await axios.patch<Company>(`/companies/${id}`, data);
                return response.data;
            } catch (error) {
                if (error instanceof ApiError) {
                    if (error.status === 404) {
                        throw new ApiError(404, 'Unternehmen nicht gefunden');
                    }
                    throw error;
                }
                throw new ApiError(500, 'Fehler beim Aktualisieren des Unternehmens');
            }
        },

        delete: async (id: number): Promise<void> => {
            try {
                const axios = await createAxiosInstance();
                await axios.delete(`/companies/${id}`);
            } catch (error) {
                if (error instanceof ApiError) {
                    if (error.status === 404) {
                        throw new ApiError(404, 'Unternehmen nicht gefunden');
                    }
                    throw error;
                }
                throw new ApiError(500, 'Fehler beim Löschen des Unternehmens');
            }
        },

        uploadLogo: async (companyId: number, formData: FormData): Promise<Company> => {
            try {
                const axios = await createAxiosInstance();
                const response = await axios.post<Company>(
                    `/companies/${companyId}/logo`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                return response.data;
            } catch (error) {
                if (error instanceof ApiError) {
                    throw error;
                }
                throw new ApiError(500, 'Fehler beim Upload des Logos');
            }
        },

        deleteLogo: async (companyId: number): Promise<void> => {
            try {
                const axios = await createAxiosInstance();
                await axios.delete(`/companies/${companyId}/logo`);
            } catch (error) {
                if (error instanceof ApiError) {
                    if (error.status === 404) {
                        throw new ApiError(404, 'Unternehmen nicht gefunden');
                    }
                    throw error;
                }
                throw new ApiError(500, 'Fehler beim Löschen des Logos');
            }
        }
    },

    posts: {
        list: async (): Promise<Post[]> => {
            try {
                const axios = await createAxiosInstance();
                const response = await axios.get<Post[]>('/posts');
                return response.data;
            } catch (error) {
                if (error instanceof ApiError) {
                    throw error;
                }
                throw new ApiError(500, 'Fehler beim Laden der Beiträge');
            }
        },

        get: async (id: number): Promise<Post> => {
            try {
                const axios = await createAxiosInstance();
                const response = await axios.get<Post>(`/posts/${id}`);
                return response.data;
            } catch (error) {
                if (error instanceof ApiError) {
                    if (error.status === 404) {
                        throw new ApiError(404, 'Beitrag nicht gefunden');
                    }
                    throw error;
                }
                throw new ApiError(500, 'Fehler beim Laden des Beitrags');
            }
        },

        create: async (data: {
            company_id: number;
            title: string;
            content: string;
            original_content: string;
        }): Promise<Post> => {
            try {
                const axios = await createAxiosInstance();
                const response = await axios.post<Post>('/posts', data);
                return response.data;
            } catch (error) {
                if (error instanceof ApiError) {
                    throw error;
                }
                throw new ApiError(500, 'Fehler beim Erstellen des Beitrags');
            }
        },

        update: async (id: number, data: Partial<Post>): Promise<Post> => {
            try {
                const axios = await createAxiosInstance();
                const response = await axios.patch<Post>(`/posts/${id}`, data);
                return response.data;
            } catch (error) {
                if (error instanceof ApiError) {
                    if (error.status === 404) {
                        throw new ApiError(404, 'Beitrag nicht gefunden');
                    }
                    throw error;
                }
                throw new ApiError(500, 'Fehler beim Aktualisieren des Beitrags');
            }
        },

        delete: async (id: number): Promise<void> => {
            try {
                const axios = await createAxiosInstance();
                await axios.delete(`/posts/${id}`);
            } catch (error) {
                if (error instanceof ApiError) {
                    if (error.status === 404) {
                        throw new ApiError(404, 'Beitrag nicht gefunden');
                    }
                    throw error;
                }
                throw new ApiError(500, 'Fehler beim Löschen des Beitrags');
            }
        },

        generateStream: async function* (data: {
            company_id: number;
            content: string;
        }): AsyncGenerator<string, void, unknown> {
            try {
                const response = await fetch(buildApiUrl('posts/generate'), {
                    method: 'POST',
                    headers: getAuthHeaders(),
                    body: JSON.stringify({
                        company_id: data.company_id,
                        context: {
                            content: data.content,
                        },
                    }),
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new ApiError(response.status, 'Generierung fehlgeschlagen');
                }

                const reader = response.body?.getReader();
                if (!reader) throw new Error('Keine Response vom Server');

                const decoder = new TextDecoder();
                let buffer = '';

                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;

                    buffer += decoder.decode(value, { stream: true });
                    let lines = buffer.split('\n');
                    buffer = lines.pop() || '';

                    for (const line of lines) {
                        if (line.startsWith('data: ')) {
                            const dataStr = line.slice(6);
                            if (dataStr === '[DONE]') return;

                            try {
                                const parsed = JSON.parse(dataStr);
                                if (parsed.error) throw new ApiError(500, parsed.error);
                                if (parsed.content) yield parsed.content;
                            } catch (e) {
                                console.error('Fehler beim Parsen der Daten:', e);
                            }
                        }
                    }
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    throw error;
                }
                throw new ApiError(500, 'Fehler bei der Generierung');
            }
        }
    }
};

export const apiService = api;
export default api;