// frontend/src/components/posts/PostCreate.tsx
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Send, Image, Wand2, ChevronDown, ArrowLeft, AlertCircle } from 'lucide-react';
import { type Company } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { usePostContext } from '../../context/PostContext';
import { enhancedApi as api } from '../../services/api-fixes';

const API_BASE = 'http://localhost:5002';

export const PostCreate: React.FC = () => {
  const navigate = useNavigate();
  const { refreshPosts } = usePostContext();
  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [originalContent, setOriginalContent] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const loadCompanies = async () => {
      try {
        const data = await api.companies.list();
        setCompanies(data);
        if (data.length > 0) {
          setSelectedCompany(String(data[0].id));
        }
      } catch (error) {
        setError('Fehler beim Laden der Unternehmen');
        console.error('Error:', error);
      }
    };

    loadCompanies();
  }, []);

  const handleGenerate = async () => {
    if (!selectedCompany || !content) {
      setError('Bitte wählen Sie ein Unternehmen und geben Sie einen Inhalt ein.');
      return;
    }

    setIsGenerating(true);
    setError('');
    setOriginalContent(content);
    
    let currentContent = '';

    try {
      const generator = api.posts.generateStream({
        company_id: parseInt(selectedCompany),
        content: content
      });

      for await (const chunk of generator) {
        // Append new chunk to current content
        currentContent += chunk;
        // Update state with accumulated content
        setContent(currentContent);
      }
    } catch (error) {
      console.error('Generation error:', error);
      setError(error instanceof Error ? error.message : 'Fehler bei der Generierung');
      setContent(originalContent); // Reset to original content on error
    } finally {
      setIsGenerating(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedCompany || !title || !content) {
      setError('Bitte füllen Sie alle erforderlichen Felder aus.');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      const newPost = await api.posts.create({
        company_id: parseInt(selectedCompany),
        title,
        content,
        original_content: originalContent,
      });

      await refreshPosts();
      navigate(`/posts/${newPost.id}`, { replace: true });
    } catch (error) {
      setError('Fehler beim Speichern des Beitrags');
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Get selected company data
  const selectedCompanyData = companies.find(c => c.id === parseInt(selectedCompany));
  const logoUrl = selectedCompanyData?.logo_url;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="rounded-lg shadow-lg bg-white"
    >
      <div className="p-4 md:p-6">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => navigate('/posts')}
          className="mb-6 flex items-center text-[rgb(58,79,102)] hover:text-[rgb(83,103,123)] transition-colors"
        >
          <ArrowLeft size={20} className="mr-2" />
          <span>Zurück zur Übersicht</span>
        </motion.button>

        <AnimatePresence>
          {error && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className="mb-4 p-4 bg-red-50 text-red-700 rounded-lg flex items-center"
            >
              <AlertCircle className="mr-2" size={20} />
              {error}
            </motion.div>
          )}
        </AnimatePresence>

        <div className="flex items-center mb-6">
          {selectedCompanyData && logoUrl ? (
            <img
              src={logoUrl.startsWith('http') ? logoUrl : `${API_BASE}${logoUrl}`}
              alt={`${selectedCompanyData.name} Logo`}
              className="w-10 h-10 rounded-full mr-4 object-cover"
              onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                const target = e.target as HTMLImageElement;
                target.onerror = null;
                target.style.display = 'none';
              }}
            />
          ) : (
            <motion.div
              className="w-10 h-10 bg-[rgb(58,79,102)] rounded-full mr-4 flex items-center justify-center text-white text-lg font-bold"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {selectedCompanyData?.name.charAt(0) || '?'}
            </motion.div>
          )}
          <h2 className="text-xl md:text-2xl font-bold text-[rgb(58,79,102)]">Neuen Beitrag erstellen</h2>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="relative">
            <select
              value={selectedCompany}
              onChange={(e) => setSelectedCompany(e.target.value)}
              className="w-full p-3 rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-[rgb(255,138,76)] bg-[rgb(244,245,246)] text-[rgb(58,79,102)]"
              required
            >
              <option value="">Unternehmen auswählen</option>
              {companies.map((company) => (
                <option key={company.id} value={company.id}>{company.name}</option>
              ))}
            </select>
            <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none text-[rgb(83,103,123)]" size={20} />
          </div>

          <input
            type="text"
            placeholder="Beitragstitel"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-[rgb(255,138,76)] bg-[rgb(244,245,246)] text-[rgb(58,79,102)]"
            required
          />

          <div className="relative space-y-2">
            <textarea
              placeholder="Was möchten Sie mitteilen?"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              className="w-full p-3 rounded-lg h-32 resize-none focus:outline-none focus:ring-2 focus:ring-[rgb(255,138,76)] bg-[rgb(244,245,246)] text-[rgb(58,79,102)] whitespace-pre-wrap"
              style={{
                display: 'block',
                minHeight: '8rem',
                whiteSpace: 'pre-wrap',
                overflowWrap: 'break-word',
                wordWrap: 'break-word',
                overflowY: 'auto'
              }}
              required
            />

            <AnimatePresence>
              {isGenerating && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="absolute top-2 right-2 flex items-center space-x-2 bg-white/75 px-2 py-1 rounded"
                >
                  <motion.div
                    animate={{ scale: [1, 1.2, 1] }}
                    transition={{ repeat: Infinity, duration: 1 }}
                    className="h-2 w-2 bg-[rgb(255,138,76)] rounded-full"
                  />
                  <span className="text-sm text-[rgb(58,79,102)]">Generiere...</span>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-2 md:space-y-0">
            <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                type="button"
                className="flex items-center justify-center space-x-2 p-2 rounded-lg transition-colors text-[rgb(58,79,102)] hover:bg-[rgb(244,245,246)]"
              >
                <Image size={20} />
                <span>Bilder hinzufügen</span>
              </motion.button>

              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                type="button"
                onClick={handleGenerate}
                disabled={isGenerating || !selectedCompany || !content}
                className={`flex items-center justify-center space-x-2 p-2 rounded-lg transition-colors ${
                  isGenerating || !selectedCompany || !content 
                    ? 'opacity-50 cursor-not-allowed bg-[rgb(244,245,246)]' 
                    : 'text-[rgb(58,79,102)] hover:bg-[rgb(244,245,246)]'
                }`}
              >
                <Wand2 size={20} />
                <span>{isGenerating ? 'Generiere...' : 'Beitrag generieren'}</span>
              </motion.button>
            </div>

            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="submit"
              disabled={isLoading || !selectedCompany || !title || !content}
              className={`w-full md:w-auto bg-[rgb(255,138,76)] text-white px-4 py-2 rounded-lg transition-colors flex items-center justify-center space-x-2 ${
                isLoading || !selectedCompany || !title || !content 
                  ? 'opacity-50 cursor-not-allowed' 
                  : 'hover:bg-[rgb(224,121,66)]'
              }`}
            >
              <Send size={20} />
              <span>{isLoading ? 'Speichert...' : 'Veröffentlichen'}</span>
            </motion.button>
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default PostCreate;