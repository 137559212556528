import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Building, Upload, Save, Trash2, Edit, X, Loader2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { apiService as api } from '../../services/api';
import { usePostContext } from '../../context/PostContext';

interface Company {
  id: number;
  name: string;
  logo_url?: string;
  prompt_template: string;
  created_at: string;
}

interface CompanyCardProps {
  company: Company;
  onRefresh: () => Promise<void>;
}

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in Bytes

const CompanyCard: React.FC<CompanyCardProps> = ({ company, onRefresh }) => {
  const navigate = useNavigate();
  const { refreshCompanies } = usePostContext();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState('');
  const [isUploadMode, setIsUploadMode] = useState(false);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size > MAX_FILE_SIZE) {
        setError('Das Logo darf nicht größer als 5MB sein');
        return;
      }
      if (!file.type.startsWith('image/')) {
        setError('Nur Bilddateien sind erlaubt');
        return;
      }
      setSelectedFile(file);
      setError('');
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    try {
      setIsUploading(true);
      setError('');

      const formData = new FormData();
      formData.append('file', selectedFile);

      await api.companies.uploadLogo(company.id, formData);
      await refreshCompanies();
      await onRefresh();
      setSelectedFile(null);
      setIsUploadMode(false);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Ein Fehler ist aufgetreten');
    } finally {
      setIsUploading(false);
    }
  };

  const handleDeleteLogo = async () => {
    if (!window.confirm('Möchten Sie das Logo wirklich löschen?')) return;

    try {
      await api.companies.deleteLogo(company.id);
      await onRefresh();
    } catch (err) {
      setError('Fehler beim Löschen des Logos');
    }
  };

  const handleDeleteCompany = async () => {
    if (!window.confirm(`Möchten Sie das Unternehmen "${company.name}" wirklich löschen?`)) {
      return;
    }

    try {
      setIsDeleting(true);
      setError('');
      await api.companies.delete(company.id);
      await refreshCompanies();
      await onRefresh();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Fehler beim Löschen des Unternehmens');
    } finally {
      setIsDeleting(false);
    }
  };

  const renderLogo = () => {
    if (company.logo_url) {
      const logoUrl = `${process.env.REACT_APP_API_URL?.replace('/api', '')}/static/company_logos/${company.logo_url.split('/').pop()}`;

      return (
        <div className="relative group">
          <motion.img
            src={logoUrl}
            alt={`${company.name} Logo`}
            className="w-40 h-40 object-contain mb-2 rounded-lg"
            whileHover={{ scale: 1.05 }}
            onError={() => {
              const imgElement = document.querySelector(`img[src="${logoUrl}"]`) as HTMLImageElement | null;
              if (imgElement?.parentElement) {
                imgElement.style.display = 'none';

                const fallbackElement = document.createElement('div');
                fallbackElement.className = 'w-40 h-40 bg-[rgb(244,245,246)] rounded-lg flex items-center justify-center mb-2';
                fallbackElement.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="rgb(83,103,123)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="2" y="2" width="20" height="20" rx="2.18" ry="2.18"></rect><line x1="7" y1="2" x2="7" y2="22"></line><line x1="17" y1="2" x2="17" y2="22"></line><line x1="2" y1="12" x2="22" y2="12"></line><line x1="2" y1="7" x2="7" y2="7"></line><line x1="2" y1="17" x2="7" y2="17"></line><line x1="17" y1="17" x2="22" y2="17"></line><line x1="17" y1="7" x2="22" y2="7"></line></svg>';
                imgElement.parentElement.appendChild(fallbackElement);
              }
            }}
          />
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={handleDeleteLogo}
            className="absolute top-2 right-2 p-2 bg-red-100 rounded-full text-red-500
                     hover:bg-red-200 opacity-0 group-hover:opacity-100 transition-all duration-300"
          >
            <Trash2 size={20} />
          </motion.button>
        </div>
      );
    }

    return (
      <motion.div
        className="w-40 h-40 bg-[rgb(244,245,246)] rounded-lg flex items-center justify-center mb-2"
        whileHover={{ scale: 1.05 }}
      >
        <Building className="text-[rgb(83,103,123)]" size={48} />
      </motion.div>
    );
  };

  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      className="p-6 rounded-lg shadow-lg transition-all bg-white border border-[rgb(244,245,246)]"
    >
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-xl font-semibold text-[rgb(58,79,102)]">{company.name}</h3>
        <div className="flex items-center gap-2">
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => navigate(`/companies/${company.id}/edit`)}
            className="text-[rgb(83,103,123)] hover:text-[rgb(58,79,102)] transition-colors p-2"
          >
            <Edit size={20} />
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={handleDeleteCompany}
            disabled={isDeleting}
            className={`p-2 text-red-500 hover:bg-red-50 rounded-lg transition-colors
              ${isDeleting ? 'opacity-50 cursor-not-allowed' : 'hover:text-red-700'}`}
          >
            {isDeleting ? (
              <Loader2 className="animate-spin" size={20} />
            ) : (
              <Trash2 size={20} />
            )}
          </motion.button>
        </div>
      </div>

      <div className="flex flex-col items-center mb-6">
        {renderLogo()}
      </div>

      <AnimatePresence>
        {error && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="mb-4 p-4 bg-red-50 text-red-700 rounded-lg text-sm flex items-center"
          >
            <X className="mr-2" size={20} />
            {error}
          </motion.div>
        )}
      </AnimatePresence>

      <div className="space-y-3">
        {isUploadMode ? (
          <div className="space-y-3">
            <input
              type="file"
              onChange={handleFileSelect}
              accept="image/*"
              className="w-full text-sm text-[rgb(83,103,123)] file:mr-4 file:py-2
                       file:px-4 file:rounded-lg file:border-0 file:bg-[rgb(255,138,76)]
                       file:text-white hover:file:bg-[rgb(224,121,66)] transition-colors"
            />
            <div className="flex gap-3">
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={handleUpload}
                disabled={!selectedFile || isUploading}
                className={`flex-1 flex items-center justify-center space-x-2 p-3 
                         rounded-lg transition-all ${
                           isUploading || !selectedFile
                             ? 'bg-[rgb(244,245,246)] cursor-not-allowed'
                             : 'bg-[rgb(255,138,76)] text-white hover:bg-[rgb(224,121,66)]'
                         }`}
              >
                <Save size={20} />
                <span>{isUploading ? 'Lädt...' : 'Speichern'}</span>
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => setIsUploadMode(false)}
                className="p-3 rounded-lg text-[rgb(83,103,123)] hover:bg-[rgb(244,245,246)] transition-colors"
              >
                Abbrechen
              </motion.button>
            </div>
          </div>
        ) : (
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => setIsUploadMode(true)}
            className="w-full flex items-center justify-center space-x-2 p-3
                     rounded-lg text-[rgb(58,79,102)] hover:bg-[rgb(244,245,246)] transition-colors"
          >
            <Upload size={20} />
            <span>Logo {company.logo_url ? 'ändern' : 'hochladen'}</span>
          </motion.button>
        )}
      </div>

      <div className="mt-6 text-sm text-[rgb(83,103,123)]">
        Erstellt am: {new Date(company.created_at).toLocaleDateString()}
      </div>
    </motion.div>
  );
};

export default CompanyCard;