import React, { useEffect, useState, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { PostItem } from './PostItem';
import { type Post } from '../../services/api';
import { PlusCircle, Search, Filter, X } from 'lucide-react';
import { Link, useSearchParams } from 'react-router-dom';
import { useDebounce } from '../../hooks/useDebounce';
import { usePostContext } from '../../context/PostContext';

export const PostList: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { companies, posts: contextPosts, refreshPosts } = usePostContext();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  // Filter States
  const [searchTerm, setSearchTerm] = useState(searchParams.get('search') || '');
  const [selectedCompany, setSelectedCompany] = useState(searchParams.get('company') || 'all');
  const [dateRange, setDateRange] = useState({
    start: searchParams.get('startDate') || '',
    end: searchParams.get('endDate') || ''
  });
  const [showFilters, setShowFilters] = useState(false);

  // Debounce search term to prevent too many API calls
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const fetchPosts = useCallback(async () => {
    try {
      setIsLoading(true);
      await refreshPosts();
    } catch (error) {
      setError('Fehler beim Laden der Beiträge');
      console.error('Fehler:', error);
    } finally {
      setIsLoading(false);
    }
  }, [refreshPosts]);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  // Update URL parameters when filters change
  useEffect(() => {
    const params: { [key: string]: string } = {};
    if (debouncedSearchTerm) params.search = debouncedSearchTerm;
    if (selectedCompany !== 'all') params.company = selectedCompany;
    if (dateRange.start) params.startDate = dateRange.start;
    if (dateRange.end) params.endDate = dateRange.end;

    setSearchParams(params);
  }, [debouncedSearchTerm, selectedCompany, dateRange, setSearchParams]);

  const filteredPosts = contextPosts.filter(post => {
    const matchesSearch = !debouncedSearchTerm ||
      post.title.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
      post.content.toLowerCase().includes(debouncedSearchTerm.toLowerCase());

    const matchesCompany = selectedCompany === 'all' ||
      post.company_id?.toString() === selectedCompany;

    const postDate = new Date(post.created_at);
    const matchesDateRange = (!dateRange.start || postDate >= new Date(dateRange.start)) &&
      (!dateRange.end || postDate <= new Date(dateRange.end));

    return matchesSearch && matchesCompany && matchesDateRange;
  });

  const resetFilters = () => {
    setSearchTerm('');
    setSelectedCompany('all');
    setDateRange({ start: '', end: '' });
    setSearchParams({});
  };

  return (
    <div className="flex flex-col h-full">
      {/* Fixierter Header-Bereich */}
      <div className="flex-none bg-white rounded-t-lg shadow-lg">
        <div className="p-4 md:p-6">
          {/* Header */}
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center">
              <h2 className="text-xl md:text-2xl font-bold text-[rgb(58,79,102)]">Alle Beiträge</h2>
            </div>
            <Link to="/posts/new">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-[rgb(255,138,76)] text-white px-4 py-2 rounded-lg transition-colors flex items-center space-x-2 hover:bg-[rgb(224,121,66)]"
              >
                <PlusCircle size={20} />
                <span>Neuer Beitrag</span>
              </motion.button>
            </Link>
          </div>

          {/* Suchleiste und Filter */}
          <div className="space-y-4">
            <div className="flex gap-4">
              <div className="relative flex-1">
                <input
                  type="text"
                  placeholder="Beiträge suchen"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full py-2 pl-10 pr-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-[rgb(255,138,76)] bg-[rgb(244,245,246)] text-[rgb(58,79,102)]"
                />
                <Search className="absolute left-3 top-2.5 text-[rgb(83,103,123)]" size={20} />
              </div>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setShowFilters(!showFilters)}
                className="px-4 py-2 rounded-lg bg-[rgb(244,245,246)] text-[rgb(58,79,102)] hover:bg-[rgb(83,103,123)] hover:text-white transition-colors flex items-center gap-2"
              >
                <Filter size={20} />
                <span>Filter</span>
              </motion.button>
            </div>

            {/* Erweiterte Filter */}
            <AnimatePresence>
              {showFilters && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  className="bg-[rgb(244,245,246)] p-4 rounded-lg space-y-4"
                >
                  <div className="flex justify-between items-center">
                    <h3 className="font-semibold text-[rgb(58,79,102)]">Filter</h3>
                    <button
                      onClick={resetFilters}
                      className="text-sm text-[rgb(83,103,123)] hover:text-[rgb(58,79,102)]"
                    >
                      Zurücksetzen
                    </button>
                  </div>

                  <div className="grid md:grid-cols-3 gap-4">
                    {/* Unternehmensfilter */}
                    <div>
                      <label className="block text-sm font-medium text-[rgb(58,79,102)] mb-1">
                        Unternehmen
                      </label>
                      <select
                        value={selectedCompany}
                        onChange={(e) => setSelectedCompany(e.target.value)}
                        className="w-full p-2 rounded-lg bg-white border border-[rgb(83,103,123)] text-[rgb(58,79,102)] focus:outline-none focus:ring-2 focus:ring-[rgb(255,138,76)]"
                      >
                        <option value="all">Alle Unternehmen</option>
                        {companies.map((company) => (
                          <option key={company.id} value={company.id}>
                            {company.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Datumsfilter */}
                    <div>
                      <label className="block text-sm font-medium text-[rgb(58,79,102)] mb-1">
                        Von
                      </label>
                      <input
                        type="date"
                        value={dateRange.start}
                        onChange={(e) => setDateRange(prev => ({ ...prev, start: e.target.value }))}
                        className="w-full p-2 rounded-lg bg-white border border-[rgb(83,103,123)] text-[rgb(58,79,102)] focus:outline-none focus:ring-2 focus:ring-[rgb(255,138,76)]"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-[rgb(58,79,102)] mb-1">
                        Bis
                      </label>
                      <input
                        type="date"
                        value={dateRange.end}
                        onChange={(e) => setDateRange(prev => ({ ...prev, end: e.target.value }))}
                        className="w-full p-2 rounded-lg bg-white border border-[rgb(83,103,123)] text-[rgb(58,79,102)] focus:outline-none focus:ring-2 focus:ring-[rgb(255,138,76)]"
                      />
                    </div>
                  </div>

                  {/* Aktive Filter anzeigen */}
                  <div className="flex flex-wrap gap-2">
                    {debouncedSearchTerm && (
                      <span className="bg-[rgb(58,79,102)] text-white px-3 py-1 rounded-full text-sm flex items-center gap-2">
                        Suche: {debouncedSearchTerm}
                        <X
                          size={14}
                          className="cursor-pointer"
                          onClick={() => setSearchTerm('')}
                        />
                      </span>
                    )}
                    {selectedCompany !== 'all' && (
                      <span className="bg-[rgb(58,79,102)] text-white px-3 py-1 rounded-full text-sm flex items-center gap-2">
                        {companies.find(c => c.id.toString() === selectedCompany)?.name}
                        <X
                          size={14}
                          className="cursor-pointer"
                          onClick={() => setSelectedCompany('all')}
                        />
                      </span>
                    )}
                    {(dateRange.start || dateRange.end) && (
                      <span className="bg-[rgb(58,79,102)] text-white px-3 py-1 rounded-full text-sm flex items-center gap-2">
                        {dateRange.start && new Date(dateRange.start).toLocaleDateString()}
                        {dateRange.start && dateRange.end && ' - '}
                        {dateRange.end && new Date(dateRange.end).toLocaleDateString()}
                        <X
                          size={14}
                          className="cursor-pointer"
                          onClick={() => setDateRange({ start: '', end: '' })}
                        />
                      </span>
                    )}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          {/* Error Message */}
          <AnimatePresence>
            {error && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="mt-4 p-4 bg-red-50 text-red-700 rounded-lg flex items-center"
              >
                <X className="mr-2" size={20} />
                {error}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>

      {/* Scrollbarer Beitragsbereich */}
      <div className="flex-1 overflow-y-auto min-h-0 bg-white rounded-b-lg shadow-lg">
        <div className="p-4 md:p-6">
          {isLoading ? (
            <div className="flex justify-center items-center min-h-[200px]">
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ duration: 1, repeat: Infinity, ease: 'linear' }}
                className="rounded-full h-12 w-12 border-4 border-[rgb(255,138,76)] border-t-[rgb(58,79,102)]"
              />
            </div>
          ) : filteredPosts.length === 0 ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="text-center py-8 text-[rgb(83,103,123)]"
            >
              {debouncedSearchTerm || selectedCompany !== 'all' || dateRange.start || dateRange.end
                ? 'Keine Beiträge gefunden.'
                : 'Noch keine Beiträge vorhanden.'}
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="grid gap-6 md:grid-cols-2 lg:grid-cols-3"
            >
              {filteredPosts.map((post) => (
                <PostItem key={post.id} post={post} onRefresh={fetchPosts} />
              ))}
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PostList;