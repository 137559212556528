'use client'

import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { User, Key, AlertCircle, Loader2 } from 'lucide-react'
import { useAuth } from '../../context/AuthContext'

export const LoginPage = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const { login, isLoading } = useAuth()

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    if (!username || !password) {
      setError('Bitte füllen Sie alle Felder aus');
      return;
    }

    try {
      await login(username, password);
    } catch (err) {
      const errorMessage = err instanceof Error ?
        err.message :
        'Login fehlgeschlagen. Bitte überprüfen Sie Ihre Anmeldedaten.';
      setError(errorMessage);
      console.error('Login error:', errorMessage);
    }
  }

  return (
    <div className="min-h-screen bg-[rgb(244,245,246)] flex items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-white p-8 rounded-2xl shadow-xl max-w-md w-full"
      >
        <motion.div
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.3, delay: 0.2 }}
          className="text-center mb-8"
        >
          <h1 className="text-3xl font-bold text-[rgb(58,79,102)]">LinkedIn Generator</h1>
          <p className="text-[rgb(83,103,123)] mt-2">Bitte melden Sie sich an</p>
        </motion.div>

        {error && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            className="mb-4 p-4 bg-red-50 text-red-700 rounded-lg flex items-center"
          >
            <AlertCircle className="mr-2 flex-shrink-0" size={20} />
            <span>{error}</span>
          </motion.div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label
              className="block text-sm font-medium text-[rgb(58,79,102)] mb-1"
              htmlFor="username"
            >
              Benutzername
            </label>
            <div className="relative">
              <input
                id="username"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="w-full pl-10 pr-4 py-2 rounded-lg border border-[rgb(83,103,123)] focus:outline-none focus:ring-2 focus:ring-[rgb(255,138,76)] focus:border-transparent transition-all duration-200 bg-[rgb(244,245,246)]"
                placeholder="Benutzername eingeben"
                required
                autoComplete="username"
              />
              <User
                className="absolute left-3 top-2.5 text-[rgb(83,103,123)]"
                size={20}
              />
            </div>
          </div>

          <div>
            <label
              className="block text-sm font-medium text-[rgb(58,79,102)] mb-1"
              htmlFor="password"
            >
              Passwort
            </label>
            <div className="relative">
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full pl-10 pr-4 py-2 rounded-lg border border-[rgb(83,103,123)] focus:outline-none focus:ring-2 focus:ring-[rgb(255,138,76)] focus:border-transparent transition-all duration-200 bg-[rgb(244,245,246)]"
                placeholder="Passwort eingeben"
                required
                autoComplete="current-password"
              />
              <Key
                className="absolute left-3 top-2.5 text-[rgb(83,103,123)]"
                size={20}
              />
            </div>
          </div>

          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            type="submit"
            disabled={isLoading}
            className={`w-full bg-[rgb(58,79,102)] text-white py-2 px-4 rounded-lg transition-all duration-200
              ${isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-[rgb(83,103,123)] hover:shadow-md'}`}
          >
            {isLoading ? (
              <span className="flex items-center justify-center">
                <Loader2 className="animate-spin mr-2" size={20} />
                Anmeldung...
              </span>
            ) : (
              'Anmelden'
            )}
          </motion.button>
        </form>
      </motion.div>
    </div>
  )
}