// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Layout
import { MainLayout } from './components/layout/MainLayout';

// Auth Components
import { LoginPage } from './components/auth/LoginPage';
import { ProtectedRoute } from './components/auth/ProtectedRoute';
import { RequireAdmin } from './components/auth/RequireAdmin';

// Post Components
import { PostCreate } from './components/posts/PostCreate';
import { PostList } from './components/posts/PostList';
import PostDetail from './components/posts/PostDetail';
import { PostEdit } from './components/posts/PostEdit';

// Company Components
import { CompanyManagement } from './components/companies/CompanyManagement';
import CompanyEdit from './components/companies/CompanyEdit';

// User Components
import { UserManagement } from './components/users/UserManagement';
import { UserCreate } from './components/users/UserCreate';

// Contexts
import { PostProvider } from './context/PostContext';
import { AuthProvider } from './context/AuthContext';

export const App = () => {
  return (
    <Router>
      <AuthProvider>
        <PostProvider>
          <Routes>
            {/* Öffentliche Route */}
            <Route path="/login" element={<LoginPage />} />

            {/* Geschützte Routen */}
            <Route
              path="/*"
              element={
                <ProtectedRoute>
                  <MainLayout>
                    <Routes>
                      {/* Post-Routen */}
                      <Route path="/posts/new" element={<PostCreate />} />
                      <Route path="/posts/:id/edit" element={<PostEdit />} />
                      <Route path="/posts/:id" element={<PostDetail />} />
                      <Route path="/posts" element={<PostList />} />

                      {/* Company-Routen */}
                      <Route path="/companies" element={<CompanyManagement />} />
                      <Route path="/companies/:id/edit" element={<CompanyEdit />} />

                      {/* Admin-Routen */}
                      <Route
                        path="/users"
                        element={
                          <RequireAdmin>
                            <UserManagement />
                          </RequireAdmin>
                        }
                      />
                      <Route
                        path="/users/create"
                        element={
                          <RequireAdmin>
                            <UserCreate />
                          </RequireAdmin>
                        }
                      />

                      {/* Standardroute */}
                      <Route path="/" element={<PostList />} />
                    </Routes>
                  </MainLayout>
                </ProtectedRoute>
              }
            />
          </Routes>

          {/* Toast Container für Benachrichtigungen */}
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            limit={3}
            style={{ zIndex: 9999 }}
          />
        </PostProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;
