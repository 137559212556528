// frontend/src/services/api-fixes.ts

// API Base URL Configuration
declare global {
  interface Window {
    ENV?: {
      REACT_APP_API_URL?: string;
    };
  }
}

const getApiBaseUrl = (): string => {
  const isDevelopment = process.env.NODE_ENV === 'development';

  // In development, use environment variable or fallback
  if (isDevelopment) {
    // Try to get from window.ENV first (for runtime configuration)
    if (window.ENV?.REACT_APP_API_URL) {
      return window.ENV.REACT_APP_API_URL;
    }

    // Then try process.env (for build-time configuration)
    const envUrl = (process.env as any).REACT_APP_API_URL;
    if (envUrl) {
      return envUrl;
    }

    // Development fallback
    return 'http://localhost:5002/api';
  }

  // In production, use relative path
  return '/api';
};

const API_BASE = getApiBaseUrl();

// Types
export interface User {
  id: number;
  username: string;
  email: string;
  role: 'ADMIN' | 'USER';
  is_active: boolean;
  created_at: string;
  updated_at: string | null;
  profile_image_url?: string;
}

export type UserRole = 'ADMIN' | 'USER';

// Helper function for auth headers
const getAuthHeader = () => {
  const token = localStorage.getItem('auth_token');
  if (!token) {
    throw new Error('No authentication token found');
  }
  return {
    'Authorization': token.startsWith('Bearer ') ? token : `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
};

// Helper function to get auth headers without Content-Type
const getAuthHeaderWithoutContentType = () => {
  const token = localStorage.getItem('auth_token');
  if (!token) {
    throw new Error('No authentication token found');
  }
  return {
    'Authorization': token.startsWith('Bearer ') ? token : `Bearer ${token}`,
  };
};

// Helper function to build API URLs correctly
const buildApiUrl = (endpoint: string): string => {
  const cleanEndpoint = endpoint.replace(/^\/+|\/+$/g, ''); // Remove leading/trailing slashes
  return `${API_BASE}/${cleanEndpoint}`;
};

// Error handling helper
const handleApiError = async (response: Response, action: string) => {
  let errorMessage = `Failed to ${action}`;

  try {
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      const errorData = await response.json();
      errorMessage = errorData.detail || errorData.message || errorMessage;
    } else {
      const textError = await response.text();
      errorMessage = textError || `${action} failed with status ${response.status}`;
    }

    // Spezifischere Fehlermeldungen basierend auf Status Code
    switch (response.status) {
      case 404:
        errorMessage = `Resource not found while trying to ${action}`;
        break;
      case 403:
        errorMessage = `Permission denied while trying to ${action}`;
        break;
      case 401:
        errorMessage = `Unauthorized to ${action}`;
        break;
      case 400:
        errorMessage = `Invalid request while trying to ${action}: ${errorMessage}`;
        break;
    }
  } catch (e) {
    console.error('Error parsing error response:', e);
    errorMessage = `${action} failed with status ${response.status}`;
  }

  throw new Error(errorMessage);
};

// Enhanced API with comprehensive error handling
export const enhancedApi = {
  auth: {
    login: async (username: string, password: string) => {
      const formData = new URLSearchParams();
      formData.append('username', username);
      formData.append('password', password);

      try {
        const response = await fetch(buildApiUrl('auth/token'), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
          },
          body: formData,
          credentials: 'include'
        });

        if (!response.ok) {
          await handleApiError(response, 'login');
        }

        return response.json();
      } catch (error) {
        console.error('Login error:', error);
        throw error;
      }
    }
  },

  users: {
    create: async (data: {
      username: string;
      email: string;
      password: string;
      role: UserRole;
    }): Promise<User> => {
      try {
        const response = await fetch(buildApiUrl('users'), {
          method: 'POST',
          headers: getAuthHeader(),
          body: JSON.stringify(data),
          credentials: 'include'
        });

        if (!response.ok) {
          await handleApiError(response, 'create user');
        }

        return response.json();
      } catch (error) {
        console.error('User creation error:', error);
        throw error;
      }
    },

    list: async (): Promise<User[]> => {
      try {
        const response = await fetch(buildApiUrl('users'), {
          headers: getAuthHeader(),
          credentials: 'include'
        });

        if (!response.ok) {
          await handleApiError(response, 'fetch users');
        }

        return response.json();
      } catch (error) {
        console.error('User list error:', error);
        throw error;
      }
    },

    get: async (id: number): Promise<User> => {
      try {
        const response = await fetch(buildApiUrl(`users/${id}`), {
          headers: getAuthHeader(),
          credentials: 'include'
        });

        if (!response.ok) {
          await handleApiError(response, 'fetch user');
        }

        return response.json();
      } catch (error) {
        console.error('User fetch error:', error);
        throw error;
      }
    },

    update: async (id: number, data: Partial<User>): Promise<User> => {
      try {
        const response = await fetch(buildApiUrl(`users/${id}`), {
          method: 'PATCH',
          headers: getAuthHeader(),
          body: JSON.stringify(data),
          credentials: 'include'
        });

        if (!response.ok) {
          await handleApiError(response, 'update user');
        }

        return response.json();
      } catch (error) {
        console.error('User update error:', error);
        throw error;
      }
    },

    delete: async (id: number): Promise<void> => {
      try {
        console.log(`Attempting to delete user with ID: ${id}`);

        const url = buildApiUrl(`users/${id}`);
        console.log(`Delete URL: ${url}`);

        const response = await fetch(url, {
          method: 'DELETE',
          headers: getAuthHeader(),
          credentials: 'include'
        });

        console.log(`Delete response status: ${response.status}`);

        if (!response.ok) {
          await handleApiError(response, `delete user with ID ${id}`);
        }

        console.log(`Successfully deleted user with ID: ${id}`);
      } catch (error) {
        console.error('User deletion error:', error);
        throw error instanceof Error
          ? error
          : new Error('Unknown error occurred while deleting user');
      }
    },

    uploadProfileImage: async (userId: number, formData: FormData): Promise<{ profile_image_url: string }> => {
      try {
        const response = await fetch(buildApiUrl(`users/${userId}/profile-image`), {
          method: 'POST',
          headers: getAuthHeaderWithoutContentType(),
          body: formData,
          credentials: 'include'
        });

        if (!response.ok) {
          await handleApiError(response, 'upload profile image');
        }

        return response.json();
      } catch (error) {
        console.error('Profile image upload error:', error);
        throw error;
      }
    },

    deleteProfileImage: async (userId: number): Promise<void> => {
      try {
        const response = await fetch(buildApiUrl(`users/${userId}/profile-image`), {
          method: 'DELETE',
          headers: getAuthHeader(),
          credentials: 'include'
        });

        if (!response.ok) {
          await handleApiError(response, 'delete profile image');
        }
      } catch (error) {
        console.error('Profile image deletion error:', error);
        throw error;
      }
    },

    changePassword: async (userId: number, newPassword: string): Promise<void> => {
      try {
        const response = await fetch(buildApiUrl(`users/${userId}/password`), {
          method: 'PATCH',
          headers: getAuthHeader(),
          body: JSON.stringify({ password: newPassword }),
          credentials: 'include'
        });

        if (!response.ok) {
          await handleApiError(response, 'change password');
        }
      } catch (error) {
        console.error('Password change error:', error);
        throw error;
      }
    },

    toggleActive: async (userId: number, isActive: boolean): Promise<User> => {
      try {
        const response = await fetch(buildApiUrl(`users/${userId}`), {
          method: 'PATCH',
          headers: getAuthHeader(),
          body: JSON.stringify({ is_active: isActive }),
          credentials: 'include'
        });

        if (!response.ok) {
          await handleApiError(response, 'toggle user status');
        }

        return response.json();
      } catch (error) {
        console.error('User status toggle error:', error);
        throw error;
      }
    }
  },

  companies: {
    list: async () => {
      try {
        const response = await fetch(buildApiUrl('companies'), {
          headers: getAuthHeader(),
          credentials: 'include'
        });

        if (!response.ok) {
          await handleApiError(response, 'fetch companies');
        }

        return response.json();
      } catch (error) {
        console.error('Error fetching companies:', error);
        throw error;
      }
    },

    get: async (id: number) => {
      try {
        const response = await fetch(buildApiUrl(`companies/${id}`), {
          headers: getAuthHeader(),
          credentials: 'include'
        });

        if (!response.ok) {
          await handleApiError(response, 'fetch company');
        }

        return response.json();
      } catch (error) {
        console.error('Error fetching company:', error);
        throw error;
      }
    },

    create: async (data: any) => {
      try {
        const response = await fetch(buildApiUrl('companies'), {
          method: 'POST',
          headers: getAuthHeader(),
          body: JSON.stringify(data),
          credentials: 'include'
        });

        if (!response.ok) {
          await handleApiError(response, 'create company');
        }

        return response.json();
      } catch (error) {
        console.error('Error creating company:', error);
        throw error;
      }
    },

    update: async (id: number, data: any) => {
      try {
        const response = await fetch(buildApiUrl(`companies/${id}`), {
          method: 'PATCH',
          headers: getAuthHeader(),
          body: JSON.stringify(data),
          credentials: 'include'
        });

        if (!response.ok) {
          await handleApiError(response, 'update company');
        }

        return response.json();
      } catch (error) {
        console.error('Error updating company:', error);
        throw error;
      }
    }
  },

  posts: {
    list: async (): Promise<any[]> => {
      try {
        const response = await fetch(`${API_BASE}/posts/`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('auth_token')}`,
            'Content-Type': 'application/json',
          },
          credentials: 'include'
        });

        if (!response.ok) {
          const errorData = await response.json().catch(() => ({}));
          throw new Error(errorData.detail || 'Fehler beim Laden der Beiträge');
        }

        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Error fetching posts:', error);
        throw new Error(error instanceof Error ? error.message : 'Netzwerkfehler');
      }
    },

    get: async (id: number) => {
      try {
        const response = await fetch(buildApiUrl(`posts/${id}`), {
          headers: getAuthHeader(),
          credentials: 'include'
        });

        if (!response.ok) {
          await handleApiError(response, 'fetch post');
        }

        return response.json();
      } catch (error) {
        console.error('Error fetching post:', error);
        throw error;
      }
    },

    create: async (data: any) => {
      try {
        const response = await fetch(buildApiUrl('posts'), {
          method: 'POST',
          headers: getAuthHeader(),
          body: JSON.stringify(data),
          credentials: 'include'
        });

        if (!response.ok) {
          await handleApiError(response, 'create post');
        }

        return response.json();
      } catch (error) {
        console.error('Error creating post:', error);
        throw error;
      }
    },

    update: async (id: number, data: any) => {
      try {
        const response = await fetch(buildApiUrl(`posts/${id}`), {
          method: 'PATCH',
          headers: getAuthHeader(),
          body: JSON.stringify(data),
          credentials: 'include'
        });

        if (!response.ok) {
          await handleApiError(response, 'update post');
        }

        return response.json();
      } catch (error) {
        console.error('Error updating post:', error);
        throw error;
      }
    },

    delete: async (id: number) => {
      try {
        const response = await fetch(buildApiUrl(`posts/${id}`), {
          method: 'DELETE',
          headers: getAuthHeader(),
          credentials: 'include'
        });

        if (!response.ok) {
          await handleApiError(response, 'delete post');
        }
      } catch (error) {
        console.error('Error deleting post:', error);
        throw error;
      }
    },

    generateStream: async function* (data: {
      company_id: number;
      content: string;
    }): AsyncGenerator<string, void, unknown> {
      try {
        const response = await fetch(buildApiUrl('posts/generate'), {
          method: 'POST',
          headers: getAuthHeader(),
          body: JSON.stringify({
            company_id: data.company_id,
            context: {
              content: data.content,
            },
          }),
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Generation failed');
        }

        const reader = response.body?.getReader();
        if (!reader) throw new Error('No response body');

        const decoder = new TextDecoder();

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const chunk = decoder.decode(value, { stream: true });
          const lines = chunk.split('\n');

          for (const line of lines) {
            if (line.startsWith('data: ')) {
              const data = line.slice(6);
              if (data === '[DONE]') return;

              try {
                const parsed = JSON.parse(data);
                if (parsed.error) throw new Error(parsed.error);
                if (parsed.content) yield parsed.content;
              } catch (e) {
                console.error('Error parsing chunk:', e);
              }
            }
          }
        }
      } catch (error) {
        console.error('Error in generate stream:', error);
        throw error;
      }
    }
  }
};

export default enhancedApi;
