// frontend/src/components/auth/RequireAdmin.tsx
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

interface RequireAdminProps {
  children: React.ReactNode;
}

export const RequireAdmin: React.FC<RequireAdminProps> = ({ children }) => {
  const { user, isLoading } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-[#50697D] border-t-[#FF8A4C]" />
      </div>
    );
  }

  if (!user) {
    console.log('Zugriff verweigert: Nicht eingeloggt');
    return <Navigate to="/login" replace state={{ from: location.pathname }} />;
  }

  if (user.role !== 'ADMIN') {
    console.log('Zugriff verweigert: Keine Admin-Rechte');
    return <Navigate to="/posts" replace />;
}

  return <>{children}</>;
};

export default RequireAdmin;