// src/components/users/UserCreate.tsx
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { User, ArrowLeft, Mail, Lock, Upload, Shield } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { enhancedApi as api } from '../../services/api-fixes';
import { useAuth } from '../../context/AuthContext';
import { UserRole, UserCreateData } from '../../types/user';

export const UserCreate: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    role: 'USER' as UserRole
  });

  // Redirect if not admin
  React.useEffect(() => {
    if (user?.role !== 'ADMIN') {
      navigate('/posts');
    }
  }, [user, navigate]);

  const handleImageSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setError('Das Bild darf nicht größer als 5MB sein');
        return;
      }
      setProfileImage(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    if (formData.password !== formData.confirmPassword) {
      setError('Die Passwörter stimmen nicht überein');
      return;
    }

    if (formData.password.length < 6) {
      setError('Das Passwort muss mindestens 6 Zeichen lang sein');
      return;
    }

    setIsLoading(true);

    try {
      const userData: UserCreateData = {
        username: formData.username,
        email: formData.email,
        password: formData.password,
        role: formData.role
      };

      const newUser = await api.users.create(userData);

      if (profileImage && newUser.id) {
        const imageFormData = new FormData();
        imageFormData.append('file', profileImage);
        await api.users.uploadProfileImage(newUser.id, imageFormData);
      }

      navigate('/users');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Ein Fehler ist aufgetreten');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-[rgb(244,245,246)]">
      <div className="flex-1 px-4 py-6 md:px-6">
        <div className="max-w-2xl mx-auto">
          {/* Header */}
          <motion.button
            whileHover={{ x: -5 }}
            onClick={() => navigate('/users')}
            className="mb-6 flex items-center text-[rgb(83,103,123)] hover:text-[rgb(58,79,102)]"
          >
            <ArrowLeft size={20} className="mr-2" />
            <span>Zurück zur Übersicht</span>
          </motion.button>

          <div className="bg-white rounded-lg shadow-lg p-6">
            <h1 className="text-2xl font-bold text-[rgb(58,79,102)] mb-6 flex items-center">
              <User className="mr-2" />
              Neuen Benutzer erstellen
            </h1>

            {error && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                className="mb-6 p-4 bg-red-50 text-red-700 rounded-lg flex items-center"
              >
                {error}
              </motion.div>
            )}

            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Profile Image */}
              <div className="flex justify-center mb-6">
                <div className="relative">
                  {previewUrl ? (
                    <img
                      src={previewUrl}
                      alt="Profilvorschau"
                      className="w-32 h-32 rounded-full object-cover"
                    />
                  ) : (
                    <div className="w-32 h-32 rounded-full bg-[rgb(244,245,246)] flex items-center justify-center">
                      <User size={48} className="text-[rgb(83,103,123)]" />
                    </div>
                  )}
                  <label className="absolute bottom-0 right-0 bg-white rounded-full p-3 shadow-lg cursor-pointer hover:bg-[rgb(244,245,246)] transition-colors">
                    <Upload size={20} className="text-[rgb(83,103,123)]" />
                    <input
                      type="file"
                      className="hidden"
                      accept="image/*"
                      onChange={handleImageSelect}
                    />
                  </label>
                </div>
              </div>

              {/* Username */}
              <div className="relative">
                <label className="block text-sm font-medium text-[rgb(58,79,102)] mb-2">
                  Benutzername
                </label>
                <div className="relative">
                  <User className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[rgb(83,103,123)]" size={20} />
                  <input
                    type="text"
                    value={formData.username}
                    onChange={(e) => setFormData(prev => ({ ...prev, username: e.target.value }))}
                    className="w-full pl-10 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-[rgb(255,138,76)] bg-[rgb(244,245,246)]"
                    required
                  />
                </div>
              </div>

              {/* Email */}
              <div className="relative">
                <label className="block text-sm font-medium text-[rgb(58,79,102)] mb-2">
                  E-Mail
                </label>
                <div className="relative">
                  <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[rgb(83,103,123)]" size={20} />
                  <input
                    type="email"
                    value={formData.email}
                    onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                    className="w-full pl-10 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-[rgb(255,138,76)] bg-[rgb(244,245,246)]"
                    required
                  />
                </div>
              </div>

              {/* Password */}
              <div className="relative">
                <label className="block text-sm font-medium text-[rgb(58,79,102)] mb-2">
                  Passwort
                </label>
                <div className="relative">
                  <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[rgb(83,103,123)]" size={20} />
                  <input
                    type="password"
                    value={formData.password}
                    onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))}
                    className="w-full pl-10 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-[rgb(255,138,76)] bg-[rgb(244,245,246)]"
                    required
                    minLength={6}
                  />
                </div>
              </div>

              {/* Confirm Password */}
              <div className="relative">
                <label className="block text-sm font-medium text-[rgb(58,79,102)] mb-2">
                  Passwort bestätigen
                </label>
                <div className="relative">
                  <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[rgb(83,103,123)]" size={20} />
                  <input
                    type="password"
                    value={formData.confirmPassword}
                    onChange={(e) => setFormData(prev => ({ ...prev, confirmPassword: e.target.value }))}
                    className="w-full pl-10 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-[rgb(255,138,76)] bg-[rgb(244,245,246)]"
                    required
                  />
                </div>
              </div>

              {/* Role */}
              <div className="relative">
                <label className="block text-sm font-medium text-[rgb(58,79,102)] mb-2">
                  Rolle
                </label>
                <div className="relative">
                  <Shield className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[rgb(83,103,123)]" size={20} />
                  <select
                    value={formData.role}
                    onChange={(e) => setFormData(prev => ({ ...prev, role: e.target.value as UserRole }))}
                    className="w-full pl-10 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-[rgb(255,138,76)] bg-[rgb(244,245,246)] appearance-none"
                  >
                    <option value="USER">Benutzer</option>
                    <option value="ADMIN">Administrator</option>
                  </select>
                </div>
              </div>

              {/* Submit Button */}
              <div className="flex justify-end space-x-4 pt-6">
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  type="button"
                  onClick={() => navigate('/users')}
                  className="px-6 py-3 rounded-lg text-[rgb(83,103,123)] hover:bg-[rgb(244,245,246)] transition-colors"
                >
                  Abbrechen
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  type="submit"
                  disabled={isLoading}
                  className={`px-6 py-3 rounded-lg bg-[rgb(255,138,76)] text-white hover:bg-[rgb(224,121,66)] transition-colors flex items-center space-x-2 ${
                    isLoading ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  {isLoading ? (
                    <>
                      <motion.div
                        animate={{ rotate: 360 }}
                        transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                        className="w-5 h-5 border-2 border-white border-t-transparent rounded-full"
                      />
                      <span>Erstelle...</span>
                    </>
                  ) : (
                    <>
                      <User size={20} />
                      <span>Benutzer erstellen</span>
                    </>
                  )}
                </motion.button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCreate;