// src/context/PostContext.tsx
import React, { createContext, useContext, useState, useCallback } from 'react';
import { api, type Post, type Company } from '../services/api';

interface PostContextType {
  posts: Post[];
  setPosts: React.Dispatch<React.SetStateAction<Post[]>>;
  refreshPosts: () => Promise<void>;
  companies: Company[];
  setCompanies: React.Dispatch<React.SetStateAction<Company[]>>;
  selectedCompanyId: string;
  setSelectedCompanyId: React.Dispatch<React.SetStateAction<string>>;
  refreshCompanies: () => Promise<void>;
}

const PostContext = createContext<PostContextType | undefined>(undefined);

export const usePostContext = () => {
  const context = useContext(PostContext);
  if (!context) {
    throw new Error('usePostContext must be used within a PostProvider');
  }
  return context;
};

export const PostProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('all');

  const refreshPosts = useCallback(async () => {
    try {
      const fetchedPosts = await api.posts.list();
      setPosts(fetchedPosts);
    } catch (error) {
      console.error('Error refreshing posts:', error);
    }
  }, []);

  const refreshCompanies = useCallback(async () => {
    try {
      const fetchedCompanies = await api.companies.list();
      setCompanies(fetchedCompanies);
    } catch (error) {
      console.error('Error refreshing companies:', error);
    }
  }, []);

  const value = {
    posts,
    setPosts,
    refreshPosts,
    companies,
    setCompanies,
    selectedCompanyId,
    setSelectedCompanyId,
    refreshCompanies
  };

  return <PostContext.Provider value={value}>{children}</PostContext.Provider>;
};

export default PostProvider;