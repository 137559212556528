import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowLeft, Save, Image, Wand2, ChevronDown, AlertCircle } from 'lucide-react';
import { api, type Post, type Company } from '../../services/api';

export const PostEdit: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [post, setPost] = useState<Post | null>(null);  const [companies, setCompanies] = useState<Company[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState('');

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [originalContent, setOriginalContent] = useState('');
  const [companyId, setCompanyId] = useState('');

  useEffect(() => {
    const loadData = async () => {
      if (!id) return;
      try {
        setIsLoading(true);
        const [postData, companiesData] = await Promise.all([
          api.posts.get(Number(id)),
          api.companies.list()
        ]);

        setPost(postData);
        setCompanies(companiesData);
        setTitle(postData.title);
        setContent(postData.content);
        setOriginalContent(postData.original_content || '');
        setCompanyId(String(postData.company_id));
      } catch (error) {
        setError('Fehler beim Laden der Daten');
        console.error(error);
        navigate('/posts', { replace: true });
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [id, navigate]);

  const handleGenerate = async () => {
    if (!companyId || !content) {
      setError('Bitte wählen Sie ein Unternehmen und geben Sie einen Inhalt ein.');
      return;
    }

    setIsGenerating(true);
    setError('');
    setOriginalContent(content);

    try {
      for await (const text of api.posts.generateStream({
        company_id: parseInt(companyId),
        content: content
      })) {
        setContent(text);
      }
    } catch (error) {
      console.error('Generation error:', error);
      setError(error instanceof Error ? error.message : 'Fehler bei der Generierung');
      setContent(originalContent);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!id || !companyId || !title || !content) {
      setError('Bitte füllen Sie alle erforderlichen Felder aus.');
      return;
    }

    try {
      setIsSaving(true);
      await api.posts.update(Number(id), {
        title,
        content,
        company_id: Number(companyId),
        original_content: originalContent,
      });

      navigate(`/posts/${id}`, { replace: true });
    } catch (error) {
      setError('Fehler beim Speichern der Änderungen');
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: 'linear' }}
          className="rounded-full h-12 w-12 border-4 border-[rgb(255,138,76)] border-t-[rgb(58,79,102)]"
        />
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="rounded-lg shadow-lg bg-white"
    >
      <div className="p-4 md:p-6">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => navigate(`/posts/${id}`)}
          className="mb-6 flex items-center text-[rgb(58,79,102)] hover:text-[rgb(83,103,123)] transition-colors"
        >
          <ArrowLeft size={20} className="mr-2" />
          <span>Zurück zum Beitrag</span>
        </motion.button>

        <AnimatePresence>
          {error && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className="mb-4 p-4 bg-red-50 text-red-700 rounded-lg flex items-center"
            >
              <AlertCircle className="mr-2" size={20} />
              {error}
            </motion.div>
          )}
        </AnimatePresence>

        <div className="flex items-center mb-6">
          {companies.find(c => c.id === parseInt(companyId))?.logo_url ? (
            <img
              src={companies.find(c => c.id === parseInt(companyId))?.logo_url}
              alt="Company Logo"
              className="w-10 h-10 rounded-full mr-4 object-cover"
            />
          ) : (
            <motion.div
              className="w-10 h-10 bg-[rgb(58,79,102)] rounded-full mr-4 flex items-center justify-center text-white text-lg font-bold"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {companies.find(c => c.id === parseInt(companyId))?.name.charAt(0) || '?'}
            </motion.div>
          )}
          <h2 className="text-xl md:text-2xl font-bold text-[rgb(58,79,102)]">Beitrag bearbeiten</h2>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="relative">
            <select
              value={companyId}
              onChange={(e) => setCompanyId(e.target.value)}
              className="w-full p-3 rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-[rgb(255,138,76)] bg-[rgb(244,245,246)] text-[rgb(58,79,102)]"
              required
            >
              <option value="">Unternehmen auswählen</option>
              {companies.map((company) => (
                <option key={company.id} value={company.id}>{company.name}</option>
              ))}
            </select>
            <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none text-[rgb(83,103,123)]" size={20} />
          </div>

          <input
            type="text"
            placeholder="Beitragstitel"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-[rgb(255,138,76)] bg-[rgb(244,245,246)] text-[rgb(58,79,102)]"
            required
          />

          <textarea
            placeholder="Was möchten Sie mitteilen?"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            className="w-full p-3 rounded-lg h-32 resize-none focus:outline-none focus:ring-2 focus:ring-[rgb(255,138,76)] bg-[rgb(244,245,246)] text-[rgb(58,79,102)]"
            required
          />

          <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-2 md:space-y-0">
            <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                type="button"
                className="flex items-center justify-center space-x-2 p-2 rounded-lg transition-colors text-[rgb(58,79,102)] hover:bg-[rgb(244,245,246)]"
              >
                <Image size={20} />
                <span>Bilder bearbeiten</span>
              </motion.button>

              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                type="button"
                onClick={handleGenerate}
                disabled={isGenerating || !companyId || !title}
                className={`flex items-center justify-center space-x-2 p-2 rounded-lg transition-colors ${
                  isGenerating || !companyId || !title 
                    ? 'opacity-50 cursor-not-allowed bg-[rgb(244,245,246)]' 
                    : 'text-[rgb(58,79,102)] hover:bg-[rgb(244,245,246)]'
                }`}
              >
                <Wand2 size={20} />
                <span>{isGenerating ? 'Generiere...' : 'Neu generieren'}</span>
              </motion.button>
            </div>

            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="submit"
              disabled={isSaving || !companyId || !title || !content}
              className={`w-full md:w-auto bg-[rgb(255,138,76)] text-white px-4 py-2 rounded-lg transition-colors flex items-center justify-center space-x-2 ${
                isSaving || !companyId || !title || !content 
                  ? 'opacity-50 cursor-not-allowed' 
                  : 'hover:bg-[rgb(224,121,66)]'
              }`}
            >
              <Save size={20} />
              <span>{isSaving ? 'Speichert...' : 'Änderungen speichern'}</span>
            </motion.button>
          </div>
        </form>
      </div>
    </motion.div>
  );
};

