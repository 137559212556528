import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Calendar,
  Building,
  ArrowLeft,
  Edit,
  Trash2,
  ChevronLeft,
  ChevronRight,
  AlertCircle,
} from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { api, type Post } from '../../services/api';

// Explizit die API_BASE URL definieren
const API_BASE = process.env.REACT_APP_API_URL || 'http://localhost:5002';

// Separate Komponente für das Firmenlogo
const CompanyLogo: React.FC<{ company: Post['company'] }> = ({ company }) => {
  const [showFallback, setShowFallback] = useState(false);

  if (!company?.name) return null;

  const firstLetter = company.name.charAt(0);

  const FallbackLogo = () => (
    <motion.div
      className="w-10 h-10 bg-[rgb(58,79,102)] rounded-full mr-4 flex items-center justify-center text-white text-lg font-bold"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      {firstLetter}
    </motion.div>
  );

  if (!company.logo_url) {
    return <FallbackLogo />;
  }

  const logoUrl = company.logo_url.startsWith('http')
    ? company.logo_url
    : `${API_BASE}${company.logo_url}`;

  if (showFallback) {
    return <FallbackLogo />;
  }

  return (
    <img
      src={logoUrl}
      alt={`${company.name} Logo`}
      className="w-10 h-10 rounded-full mr-4 object-cover"
      onError={() => {
        console.error('Logo loading error for URL:', logoUrl);
        setShowFallback(true);
      }}
    />
  );
};

const PostDetail: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState<Post | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const fetchPost = async () => {
      if (!id) return;
      try {
        const data = await api.posts.get(parseInt(id));
        setPost(data);
      } catch (error) {
        setError('Fehler beim Laden des Beitrags');
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPost();
  }, [id]);

  const handleDelete = async () => {
    if (!post || !window.confirm('Möchten Sie diesen Beitrag wirklich löschen?')) return;
    try {
      await api.posts.delete(post.id);
      navigate('/posts', { replace: true });
    } catch (error) {
      console.error('Fehler beim Löschen:', error);
      setError('Fehler beim Löschen des Beitrags');
    }
  };

  const handleImageNavigation = (direction: 'prev' | 'next') => {
    if (!post?.images || post.images.length === 0) return;

    const images = post.images;

    setCurrentImageIndex((prevIndex) => {
      if (direction === 'prev') {
        return prevIndex === 0 ? images.length - 1 : prevIndex - 1;
      } else {
        return prevIndex === images.length - 1 ? 0 : prevIndex + 1;
      }
    });
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: 'linear' }}
          className="rounded-full h-12 w-12 border-4 border-[rgb(255,138,76)] border-t-[rgb(58,79,102)]"
        />
      </div>
    );
  }

  if (error || !post) {
    return (
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-red-50 text-red-700 p-4 rounded-lg flex items-center"
      >
        <AlertCircle className="mr-2" size={20} />
        {error || 'Beitrag nicht gefunden'}
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="max-w-4xl mx-auto"
    >
      <div className="mb-6">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => navigate('/posts')}
          className="mb-4 flex items-center text-[rgb(58,79,102)] hover:text-[rgb(83,103,123)] transition-colors"
        >
          <ArrowLeft size={20} className="mr-2" />
          <span>Zurück zur Übersicht</span>
        </motion.button>

        <div className="flex justify-between items-start">
          <div className="flex items-center">
            <CompanyLogo company={post.company} />
            <h1 className="text-3xl font-bold text-[rgb(58,79,102)]">{post.title}</h1>
          </div>
          <div className="flex gap-2">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => navigate(`/posts/${post.id}/edit`)}
              className="flex items-center justify-center space-x-2 p-2 rounded-lg transition-colors text-[rgb(58,79,102)] hover:bg-[rgb(244,245,246)]"
            >
              <Edit size={20} />
              <span className="hidden sm:inline">Bearbeiten</span>
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleDelete}
              className="flex items-center justify-center space-x-2 p-2 rounded-lg transition-colors text-red-600 hover:bg-red-50"
            >
              <Trash2 size={20} />
              <span className="hidden sm:inline">Löschen</span>
            </motion.button>
          </div>
        </div>
      </div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="bg-white rounded-lg shadow-lg overflow-hidden"
      >
        {post.images && post.images.length > 0 && (
          <div className="relative h-[400px]">
            <AnimatePresence initial={false}>
              <motion.img
                key={currentImageIndex}
                src={
                  post.images[currentImageIndex].startsWith('http')
                    ? post.images[currentImageIndex]
                    : `${API_BASE}${post.images[currentImageIndex]}`
                }
                alt={`Bild ${currentImageIndex + 1} von ${post.title}`}
                className="w-full h-full object-cover"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                  const target = e.target as HTMLImageElement;
                  target.onerror = null;
                  target.style.display = 'none';
                }}
              />
            </AnimatePresence>
            {post.images.length > 1 && (
              <>
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => handleImageNavigation('prev')}
                  className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white/50 rounded-full p-2"
                >
                  <ChevronLeft size={24} />
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => handleImageNavigation('next')}
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white/50 rounded-full p-2"
                >
                  <ChevronRight size={24} />
                </motion.button>
                <div className="absolute bottom-4 left-0 right-0 flex justify-center gap-2">
                  {post.images.map((_, index) => (
                    <motion.button
                      key={index}
                      className={`w-2 h-2 rounded-full ${
                        index === currentImageIndex ? 'bg-white' : 'bg-white/50'
                      }`}
                      onClick={() => setCurrentImageIndex(index)}
                      whileHover={{ scale: 1.2 }}
                      whileTap={{ scale: 0.8 }}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        )}

        <div className="p-6">
          <div className="flex items-center gap-4 text-sm text-[rgb(83,103,123)] mb-4">
            <div className="flex items-center">
              <Calendar size={16} className="mr-1 text-[rgb(58,79,102)]" />
              <span>{new Date(post.created_at).toLocaleDateString()}</span>
            </div>
            <div className="flex items-center">
              <Building size={16} className="mr-1 text-[rgb(58,79,102)]" />
              <span>{post.company?.name || 'Unbekanntes Unternehmen'}</span>
            </div>
          </div>

          {post.original_content && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="mb-6"
            >
              <h2 className="text-lg font-semibold mb-2 text-[rgb(58,79,102)]">Original Input:</h2>
              <p className="text-[rgb(83,103,123)] whitespace-pre-wrap bg-[rgb(244,245,246)] p-4 rounded-lg">
                {post.original_content}
              </p>
            </motion.div>
          )}

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            <h2 className="text-lg font-semibold mb-2 text-[rgb(58,79,102)]">Generierter Beitrag:</h2>
            <p className="text-[rgb(58,79,102)] whitespace-pre-wrap">{post.content}</p>
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default PostDetail;
