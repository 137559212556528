import React, { useEffect, useState, useCallback } from 'react';
import {
  PlusCircle,
  Search,
  Home,
  Building,
  LogOut,
  User,
  Users,
  X,
} from 'lucide-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { usePostContext } from '../../context/PostContext';
import { useAuth } from '../../context/AuthContext';

const API_BASE = 'http://localhost:5002';

interface NavButtonProps {
  to: string;
  icon: React.ReactNode;
  children: React.ReactNode;
  isActive?: boolean;
  onClick?: () => void;
}

interface SidebarProps {
  onClose: () => void;
  isSmallScreen: boolean;
}

const NavButton: React.FC<NavButtonProps> = ({ to, icon, children, isActive, onClick }) => (
  <Link
    to={to}
    onClick={onClick}
    className={`
      flex items-center p-3 rounded-lg transition-colors duration-200
      ${isActive 
        ? 'bg-[rgb(80,105,125)] text-[rgb(244,245,246)]' 
        : to === '/posts/new'
          ? 'bg-[rgb(255,138,76)] text-white hover:bg-[rgb(255,165,115)]'
          : 'text-[rgb(35,40,45)] hover:bg-[rgb(125,145,165)] hover:text-[rgb(244,245,246)]'
      }
    `}
  >
    <span className="mr-3">{icon}</span>
    <span className="font-medium">{children}</span>
  </Link>
);

const Sidebar: React.FC<SidebarProps> = ({ onClose, isSmallScreen }) => {
  const { posts, refreshPosts, refreshCompanies } = usePostContext();
  const { user, logout } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [profileImageError, setProfileImageError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);
        await Promise.all([refreshPosts(), refreshCompanies()]);
      } catch (err) {
        setError('Fehler beim Laden der Daten');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [refreshPosts, refreshCompanies]);

  const handleLogout = async () => {
    if (window.confirm('Möchten Sie sich wirklich abmelden?')) {
      await logout();
      navigate('/login');
    }
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    if (isSmallScreen) {
      onClose();
    }
  };

  const debouncedSearch = useCallback((value: string) => {
    setSearchTerm(value);
  }, []);

  const filteredPosts = posts.filter(
    (post) =>
      post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      post.content.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Helper function to get profile image URL
  const getProfileImageUrl = (url: string | null | undefined): string | undefined => {
    if (!url) return undefined;
    return url.startsWith('http') ? url : `${API_BASE}${url}`;
  };

  const renderProfileImage = () => {
    if (!profileImageError && user?.profile_image_url) {
      return (
        <img
          src={getProfileImageUrl(user.profile_image_url)}
          alt={`${user.username}'s profile`}
          className="w-10 h-10 rounded-full object-cover"
          onError={() => setProfileImageError(true)}
        />
      );
    }

    return (
      <div className="p-2 bg-[rgb(80,105,125)] rounded-full">
        <User size={20} className="text-[rgb(244,245,246)]" />
      </div>
    );
  };

  return (
    <aside className="w-72 h-full bg-[rgb(244,245,246)] shadow-lg flex flex-col">
      {/* Header */}
      <div className="flex items-center justify-between p-4">
        <h1 className="text-xl font-bold text-[rgb(35,40,45)]">
          LinkedIn Generator
        </h1>
        {isSmallScreen && (
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-200 rounded-full transition-colors"
          >
            <X size={20} className="text-[rgb(35,40,45)]" />
          </button>
        )}
      </div>

      {/* Navigation */}
      <nav className="px-4 py-6 space-y-2">
        <NavButton
          to="/"
          icon={<Home size={20} />}
          isActive={location.pathname === '/'}
          onClick={() => handleNavigation('/')}
        >
          Home
        </NavButton>
        <NavButton
          to="/posts/new"
          icon={<PlusCircle size={20} />}
          isActive={location.pathname === '/posts/new'}
          onClick={() => handleNavigation('/posts/new')}
        >
          Neuer Beitrag
        </NavButton>
        <NavButton
          to="/users"
          icon={<Users size={20} />}
          isActive={location.pathname === '/users'}
          onClick={() => handleNavigation('/users')}
        >
          Benutzer
        </NavButton>
        <NavButton
          to="/companies"
          icon={<Building size={20} />}
          isActive={location.pathname === '/companies'}
          onClick={() => handleNavigation('/companies')}
        >
          Unternehmen
        </NavButton>
      </nav>

      {/* Search and Posts Container */}
      <div className="flex-1 flex flex-col min-h-0">
        {/* Search */}
        <div className="p-4 bg-[rgb(244,245,246)]">
          <div className="relative">
            <input
              type="text"
              placeholder="Beiträge suchen..."
              onChange={(e) => debouncedSearch(e.target.value)}
              className="w-full p-3 pl-10 bg-white text-[rgb(35,40,45)] rounded-lg placeholder-[rgb(80,105,125)]
                focus:ring-2 focus:ring-[rgb(255,138,76)] focus:outline-none shadow-sm"
            />
            <Search
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[rgb(80,105,125)]"
              size={18}
            />
          </div>
        </div>

        {/* Scrollable Posts List */}
        <div className="flex-1 overflow-y-auto p-4">
          <h2 className="text-[rgb(35,40,45)] font-semibold mb-3">
            Aktuelle Beiträge
          </h2>
          {isLoading ? (
            <div className="text-[rgb(255,138,76)] text-center py-4">Laden...</div>
          ) : error ? (
            <div className="text-red-500 text-center py-4">{error}</div>
          ) : filteredPosts.length === 0 ? (
            <div className="text-[rgb(80,105,125)] text-center py-4">
              {searchTerm ? 'Keine Beiträge gefunden' : 'Noch keine Beiträge vorhanden'}
            </div>
          ) : (
            <div className="space-y-2">
              {filteredPosts.map((post) => (
                <button
                  key={post.id}
                  onClick={() => {
                    handleNavigation(`/posts/${post.id}`);
                  }}
                  className="w-full p-3 bg-white rounded-lg text-left transition-colors duration-200
                    hover:bg-[rgb(125,145,165)] group shadow-sm hover:shadow-md"
                >
                  <h3 className="font-medium line-clamp-1 text-[rgb(35,40,45)] group-hover:text-[rgb(244,245,246)]">
                    {post.title}
                  </h3>
                  <p className="text-sm text-[rgb(255,138,76)] mt-1 group-hover:text-[rgb(244,245,246)]">
                    {post.company?.name || 'Unbekanntes Unternehmen'}
                  </p>
                  <p className="text-xs text-[rgb(80,105,125)] mt-1 group-hover:text-[rgb(244,245,246)]">
                    {new Date(post.created_at).toLocaleDateString()}
                  </p>
                </button>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* User Profile */}
      <div className="p-4 bg-[rgb(244,245,246)]">
        <div className="flex items-center justify-between bg-white p-3 rounded-lg shadow-sm">
          <div className="flex items-center">
            {renderProfileImage()}
            <div className="ml-3">
              <p className="text-[rgb(35,40,45)] font-medium">
                {user?.username || 'Unbekannter Benutzer'}
              </p>
              <p className="text-sm text-[rgb(255,138,76)]">
                {user?.role === 'ADMIN' ? 'Administrator' : 'Benutzer'}
              </p>
            </div>
          </div>
          <button
            onClick={handleLogout}
            className="p-2 text-[rgb(255,138,76)] hover:bg-[rgb(80,105,125)] hover:text-[rgb(244,245,246)]
              rounded-full transition-colors duration-200"
          >
            <LogOut size={20} />
          </button>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;