// src/services/api-config.ts

// Environment types
declare global {
  interface Window {
    ENV?: {
      REACT_APP_API_URL?: string;
    };
  }
}

// Error class for API errors
export class ApiError extends Error {
  constructor(
    public status: number,
    message: string,
    public data?: any
  ) {
    super(message);
    this.name = 'ApiError';
  }
}

// Base URL Configuration
export const getBaseUrl = () => {
    // Priorisiere die REACT_APP_API_URL Umgebungsvariable
    const envUrl = process.env.REACT_APP_API_URL;
    if (envUrl) {
        return envUrl;
    }

    // Prüfe auf Runtime-Konfiguration im window.ENV
    if (window.ENV?.REACT_APP_API_URL) {
        return window.ENV.REACT_APP_API_URL;
    }

    // Fallback basierend auf der Umgebung
    if (process.env.NODE_ENV === 'development') {
        return 'http://localhost:5002/api';
    }

    // Produktions-Fallback
    return window.location.origin + '/api';
};

// API URL Helper
export const buildApiUrl = (path: string): string => {
  const baseUrl = getBaseUrl();
  const cleanPath = path.replace(/^\/+/, ''); // Remove leading slashes
  return `${baseUrl}/${cleanPath}`;
};

// Auth Header Configuration
export const getAuthHeaders = (includeContentType = true) => {
  const token = localStorage.getItem('auth_token');
  const headers: Record<string, string> = {
    'Authorization': token ? `Bearer ${token}` : '',
  };

  if (includeContentType) {
    headers['Content-Type'] = 'application/json';
  }

  return headers;
};

// Fetch Configuration
export const createFetchConfig = (
  method = 'GET',
  body?: any,
  includeContentType = true
): RequestInit => {
  const config: RequestInit = {
    method,
    headers: getAuthHeaders(includeContentType),
    credentials: 'include'
  };

  if (body) {
    config.body = typeof body === 'string' ? body : JSON.stringify(body);
  }

  return config;
};

// Error Handler
export const handleApiError = async (response: Response) => {
  let message = 'Ein Fehler ist aufgetreten';
  let data;

  try {
    data = await response.json();
    message = data.detail || data.message || message;
  } catch (e) {
    console.error('Error parsing error response:', e);
  }

  throw new ApiError(response.status, message, data);
};

// Request Helper
export const makeRequest = async <T>(
  url: string,
  method = 'GET',
  body?: any,
  includeContentType = true
): Promise<T> => {
  const response = await fetch(
    buildApiUrl(url),
    createFetchConfig(method, body, includeContentType)
  );

  if (!response.ok) {
    await handleApiError(response);
  }

  return response.json();
};

// Stream Helper
export const createStreamRequest = async (
  url: string,
  body?: any
): Promise<ReadableStreamDefaultReader<Uint8Array>> => {
  const response = await fetch(
    buildApiUrl(url),
    createFetchConfig('POST', body)
  );

  if (!response.ok) {
    await handleApiError(response);
  }

  const reader = response.body?.getReader();
  if (!reader) {
    throw new Error('No response body available');
  }

  return reader;
};

// Response Types
export interface ApiResponse<T = any> {
  data: T;
  message?: string;
  status: number;
}

// Common API Types
export interface PaginatedResponse<T> {
  items: T[];
  total: number;
  page: number;
  pageSize: number;
  totalPages: number;
}

export interface ApiErrorResponse {
  message: string;
  status: number;
  errors?: Record<string, string[]>;
}

// Validation Helper
export const validateApiResponse = <T>(response: unknown): response is ApiResponse<T> => {
  const apiResponse = response as ApiResponse<T>;
  return (
    apiResponse !== null &&
    typeof apiResponse === 'object' &&
    'data' in apiResponse &&
    'status' in apiResponse &&
    typeof apiResponse.status === 'number'
  );
};

// Export API configuration object
export const apiConfig = {
  getBaseUrl,
  buildApiUrl,
  getAuthHeaders,
  createFetchConfig,
  handleApiError,
  makeRequest,
  createStreamRequest,
  validateApiResponse,
};