// frontend/src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Hot Module Replacement (HMR) - mit TypeScript-konformer Prüfung
if (process.env.NODE_ENV !== 'production' && module && module.hasOwnProperty('hot') && (module as any).hot) {
  (module as any).hot.accept();
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);