// frontend/src/components/companies/CompanyManagement.tsx
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Plus, Search, X, AlertCircle, Filter, Loader2 } from 'lucide-react';
import { useDebounce } from '../../hooks/useDebounce';
import { usePostContext } from '../../context/PostContext';
import CompanyCard from './CompanyCard';
import { apiService as api } from '../../services/api';

// interface Company {
// id: number;
//  name: string;
//  prompt_template: string;
//  logo_url?: string;
//  created_at: string;
//}

export const CompanyManagement: React.FC = () => {
  const { companies, refreshCompanies } = usePostContext();
  const [error, setError] = useState('');
  const [showNewCompanyForm, setShowNewCompanyForm] = useState(false);
  const [newCompany, setNewCompany] = useState({ name: '', prompt_template: '' });
  const [isCreating, setIsCreating] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);

  // Search and filter options
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortBy, setSortBy] = useState<'name' | 'created_at'>('name');
  const [showTemplateOnly, setShowTemplateOnly] = useState(false);
  const [showLogoOnly, setShowLogoOnly] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  // Filter companies with loading state
  const filteredCompanies = React.useMemo(() => {
    setIsFiltering(true);
    try {
      return companies
        .filter((company) => {
          const matchesSearch =
            company.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
            company.prompt_template
              .toLowerCase()
              .includes(debouncedSearchTerm.toLowerCase());

          const matchesTemplate =
            !showTemplateOnly || company.prompt_template.trim().length > 0;
          const matchesLogo = !showLogoOnly || company.logo_url;

          return matchesSearch && matchesTemplate && matchesLogo;
        })
        .sort((a, b) => {
          if (sortBy === 'name') {
            return sortOrder === 'asc'
              ? a.name.localeCompare(b.name)
              : b.name.localeCompare(a.name);
          } else {
            return sortOrder === 'asc'
              ? new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
              : new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
          }
        });
    } finally {
      setIsFiltering(false);
    }
  }, [companies, debouncedSearchTerm, showTemplateOnly, showLogoOnly, sortBy, sortOrder]);

  const handleCreateCompany = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setIsCreating(true);
      setError('');
      await api.companies.create(newCompany);
      await refreshCompanies();
      setShowNewCompanyForm(false);
      setNewCompany({ name: '', prompt_template: '' });
    } catch (err) {
      setError(
        err instanceof Error ? err.message : 'Fehler beim Erstellen des Unternehmens'
      );
    } finally {
      setIsCreating(false);
    }
  };

  const resetFilters = () => {
    setSearchTerm('');
    setShowTemplateOnly(false);
    setShowLogoOnly(false);
    setSortBy('name');
    setSortOrder('asc');
  };

  return (
    <div className="flex flex-col h-full">
      {/* Fixierter Header-Bereich */}
      <div className="flex-none bg-white rounded-t-lg shadow-lg">
        <div className="p-4 md:p-6">
          {/* Header */}
          <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 mb-6">
            <h2 className="text-xl md:text-2xl font-bold text-[rgb(58,79,102)]">
              Unternehmen verwalten
            </h2>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setShowNewCompanyForm(true)}
              className="flex items-center justify-center px-4 py-2 bg-[rgb(255,138,76)] text-white rounded-lg hover:bg-[rgb(224,121,66)] transition-colors whitespace-nowrap"
            >
              <Plus size={20} className="mr-2" />
              <span>Neues Unternehmen</span>
            </motion.button>
          </div>

          {/* Search and Filters */}
          <div className="space-y-4">
            <div className="flex flex-col sm:flex-row gap-4">
              <div className="relative flex-1">
                <input
                  type="text"
                  placeholder="Unternehmen suchen..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full p-2 pl-10 pr-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-[rgb(255,138,76)] bg-[rgb(244,245,246)] text-[rgb(58,79,102)]"
                />
                <Search className="absolute left-3 top-2.5 text-[rgb(83,103,123)]" size={20} />
              </div>

              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setShowFilters(!showFilters)}
                className={`px-4 py-2 rounded-lg transition-colors flex items-center justify-center gap-2 min-w-[100px]
                  ${showFilters 
                    ? 'bg-[rgb(58,79,102)] text-white' 
                    : 'bg-[rgb(244,245,246)] text-[rgb(58,79,102)] hover:bg-[rgb(83,103,123)] hover:text-white'
                  }`}
              >
                <Filter size={20} />
                <span>Filter</span>
              </motion.button>
            </div>

            <AnimatePresence>
              {showFilters && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  className="bg-[rgb(244,245,246)] p-4 rounded-lg space-y-4"
                >
                  <div className="flex justify-between items-center">
                    <h3 className="font-semibold text-[rgb(58,79,102)]">Filter</h3>
                    <button
                      onClick={resetFilters}
                      className="text-sm text-[rgb(83,103,123)] hover:text-[rgb(58,79,102)]"
                    >
                      Zurücksetzen
                    </button>
                  </div>

                  <div className="flex flex-wrap gap-4">
                    <select
                      value={`${sortBy}-${sortOrder}`}
                      onChange={(e) => {
                        const [newSortBy, newSortOrder] = e.target.value.split(
                          '-'
                        ) as ['name' | 'created_at', 'asc' | 'desc'];
                        setSortBy(newSortBy);
                        setSortOrder(newSortOrder);
                      }}
                      className="p-2 rounded-lg border border-[rgb(83,103,123)] focus:outline-none focus:ring-2 focus:ring-[rgb(255,138,76)] text-[rgb(58,79,102)]"
                    >
                      <option value="name-asc">Name (A-Z)</option>
                      <option value="name-desc">Name (Z-A)</option>
                      <option value="created_at-asc">Datum (Älteste zuerst)</option>
                      <option value="created_at-desc">Datum (Neueste zuerst)</option>
                    </select>

                    <label className="flex items-center space-x-3">
                      <input
                        type="checkbox"
                        checked={showTemplateOnly}
                        onChange={(e) => setShowTemplateOnly(e.target.checked)}
                        className="rounded text-[rgb(255,138,76)] focus:ring-[rgb(255,138,76)] w-5 h-5"
                      />
                      <span className="text-[rgb(83,103,123)]">Nur mit Template</span>
                    </label>

                    <label className="flex items-center space-x-3">
                      <input
                        type="checkbox"
                        checked={showLogoOnly}
                        onChange={(e) => setShowLogoOnly(e.target.checked)}
                        className="rounded text-[rgb(255,138,76)] focus:ring-[rgb(255,138,76)] w-5 h-5"
                      />
                      <span className="text-[rgb(83,103,123)]">Nur mit Logo</span>
                    </label>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          {/* Error Message */}
          <AnimatePresence>
            {error && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="mt-4 p-3 bg-red-50 text-red-700 rounded-lg flex items-center"
              >
                <AlertCircle className="mr-2" size={20} />
                {error}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>

      {/* Scrollbarer Bereich */}
      <div className="flex-1 overflow-y-auto min-h-0 bg-white rounded-b-lg shadow-lg">
        <div className="p-4 md:p-6">
          {/* New Company Form */}
          <AnimatePresence>
            {showNewCompanyForm && (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="mb-6 p-6 border rounded-lg bg-[rgb(244,245,246)]"
              >
                <div className="flex items-center justify-between mb-6">
                  <h3 className="text-xl font-semibold text-[rgb(58,79,102)]">
                    Neues Unternehmen erstellen
                  </h3>
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => setShowNewCompanyForm(false)}
                    className="text-[rgb(83,103,123)] hover:text-[rgb(58,79,102)]"
                  >
                    <X size={24} />
                  </motion.button>
                </div>

                <form onSubmit={handleCreateCompany} className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-[rgb(58,79,102)] mb-2">
                      Unternehmensname
                    </label>
                    <input
                      type="text"
                      value={newCompany.name}
                      onChange={(e) =>
                        setNewCompany({ ...newCompany, name: e.target.value })
                      }
                      className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[rgb(255,138,76)] focus:outline-none bg-white text-[rgb(58,79,102)]"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-[rgb(58,79,102)] mb-2">
                      Prompt Template
                    </label>
                    <textarea
                      value={newCompany.prompt_template}
                      onChange={(e) =>
                        setNewCompany({ ...newCompany, prompt_template: e.target.value })
                      }
                      className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[rgb(255,138,76)] focus:outline-none bg-white text-[rgb(58,79,102)] h-32"
                      required
                    />
                  </div>

                  <div className="flex justify-end space-x-4">
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      type="button"
                      onClick={() => setShowNewCompanyForm(false)}
                      className="px-4 py-2 text-[rgb(83,103,123)] hover:bg-[rgb(244,245,246)] rounded-lg transition-colors"
                    >
                      Abbrechen
                    </motion.button>

                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      type="submit"
                      disabled={isCreating}
                      className={`px-4 py-2 bg-[rgb(255,138,76)] text-white rounded-lg flex items-center space-x-2
                        ${isCreating ? 'opacity-50 cursor-not-allowed' : 'hover:bg-[rgb(224,121,66)]'}`}
                    >
                      {isCreating ? (
                        <>
                          <Loader2 className="animate-spin" size={20} />
                          <span>Erstelle...</span>
                        </>
                      ) : (
                        <>
                          <Plus size={20} />
                          <span>Erstellen</span>
                        </>
                      )}
                    </motion.button>
                  </div>
                </form>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Companies Grid */}
          <div className="grid gap-4 sm:gap-6 md:grid-cols-2 lg:grid-cols-3">
            {isFiltering ? (
              <div className="col-span-full flex justify-center py-12">
                <Loader2 className="animate-spin text-[rgb(255,138,76)]" size={40} />
              </div>
            ) : filteredCompanies.length === 0 ? (
              <div className="col-span-full text-center py-12 text-[rgb(83,103,123)] text-lg">
                {debouncedSearchTerm || showTemplateOnly || showLogoOnly
                  ? 'Keine Unternehmen gefunden.'
                  : 'Noch keine Unternehmen vorhanden.'}
              </div>
            ) : (
              filteredCompanies.map((company) => (
                <CompanyCard
                  key={company.id}
                  company={company}
                  onRefresh={refreshCompanies}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyManagement;