// src/components/users/UserManagement.tsx
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import {
  Users,
  PlusCircle,
  Trash2,
  Shield,
  UserIcon,
  Upload,
  Edit,
  X
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { enhancedApi as api } from '../../services/api-fixes';
import { useAuth } from '../../context/AuthContext';
import { User, UserRole, UserUpdateData } from '../../types/user';
import { User as ApiUser } from '../../services/api-fixes';
import { toast } from 'react-toastify';

interface UserEditDialogProps {
  user: User;
  onClose: () => void;
  onSave: (updatedUser: UserUpdateData) => Promise<void>;
}

const UserEditDialog: React.FC<UserEditDialogProps> = ({ user, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    username: user.username,
    email: user.email,
    role: user.role as UserRole,
    password: '',
    profileImage: null as File | null
  });
  const [previewUrl, setPreviewUrl] = useState<string | null>(user.profile_image_url);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleImageSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setError('Das Bild darf nicht größer als 5MB sein');
        return;
      }
      setFormData(prev => ({ ...prev, profileImage: file }));
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const updateData: UserUpdateData = {
        username: formData.username,
        email: formData.email,
        role: formData.role
      };

      if (formData.password) {
        updateData.password = formData.password;
      }

      if (formData.profileImage) {
        const imageFormData = new FormData();
        imageFormData.append('file', formData.profileImage);
        await api.users.uploadProfileImage(user.id, imageFormData);
      }

      await onSave(updateData);
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Ein Fehler ist aufgetreten');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        className="bg-white rounded-lg p-6 max-w-md w-full"
      >
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Benutzer bearbeiten</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex justify-center mb-4">
            <div className="relative">
              {previewUrl ? (
                <img
                  src={previewUrl}
                  alt="Profilbild"
                  className="w-24 h-24 rounded-full object-cover"
                />
              ) : (
                <div className="w-24 h-24 rounded-full bg-gray-200 flex items-center justify-center">
                  <UserIcon size={32} className="text-gray-400" />
                </div>
              )}
              <label className="absolute bottom-0 right-0 bg-white rounded-full p-2 shadow-lg cursor-pointer hover:bg-gray-50">
                <Upload size={20} className="text-gray-600" />
                <input
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={handleImageSelect}
                />
              </label>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Benutzername
            </label>
            <input
              type="text"
              value={formData.username}
              onChange={(e) => setFormData(prev => ({ ...prev, username: e.target.value }))}
              className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-[#50697D]"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              E-Mail
            </label>
            <input
              type="email"
              value={formData.email}
              onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
              className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-[#50697D]"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Neues Passwort (optional)
            </label>
            <input
              type="password"
              value={formData.password}
              onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))}
              className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-[#50697D]"
              placeholder="Leer lassen für keine Änderung"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Rolle
            </label>
            <select
              value={formData.role}
              onChange={(e) => setFormData(prev => ({ ...prev, role: e.target.value as UserRole }))}
              className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-[#50697D]"
            >
              <option value="USER">Benutzer</option>
              <option value="ADMIN">Administrator</option>
            </select>
          </div>

          <div className="flex justify-end space-x-2 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
            >
              Abbrechen
            </button>
            <button
              type="submit"
              disabled={isLoading}
              className="px-4 py-2 bg-[#50697D] text-white rounded-lg hover:bg-[#3A4D5C] disabled:opacity-50"
            >
              {isLoading ? 'Speichert...' : 'Speichern'}
            </button>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export const UserManagement: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [editingUser, setEditingUser] = useState<User | null>(null);

  const fetchUsers = async () => {
    setIsLoading(true);
    setError('');
    try {
      const usersList = await api.users.list();
      const convertedUsers: User[] = usersList.map(user => ({
        ...user,
        profile_image_url: user.profile_image_url || null
      }));
      setUsers(convertedUsers);
    } catch (err) {
      setError('Fehler beim Laden der Benutzer');
      console.error('Fetch users error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    const checkAdminAccess = async () => {
      try {
        if (!user) {
          return;
        }

        if (user.role !== 'ADMIN') {
          console.log('Zugriff verweigert: Keine Admin-Rechte');
          navigate('/posts');
        }
      } catch (error) {
        console.error('Fehler bei der Admin-Überprüfung:', error);
        navigate('/posts');
      }
    };
    checkAdminAccess();
  }, [user, navigate]);

  const handleEditUser = (userToEdit: User) => {
    setEditingUser(userToEdit);
  };

  const handleUpdateUser = async (userId: number, updateData: UserUpdateData) => {
    try {
      const apiUpdateData: Partial<ApiUser> = {
        username: updateData.username,
        email: updateData.email,
        role: updateData.role,
        profile_image_url: updateData.profile_image_url ?? undefined
      };

      await api.users.update(userId, apiUpdateData);
      await fetchUsers();
      setEditingUser(null);
      toast.success('Benutzer erfolgreich aktualisiert');
    } catch (err) {
      setError('Fehler beim Aktualisieren des Benutzers');
      console.error('Update user error:', err);
      toast.error('Fehler beim Aktualisieren des Benutzers');
    }
  };

  const handleDeleteUser = async (userId: number) => {
    try {
      setIsLoading(true);

      const confirmDelete = window.confirm(
        'Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?'
      );

      if (!confirmDelete) {
        return;
      }

      await api.users.delete(userId);
      await fetchUsers();
      toast.success('Benutzer wurde erfolgreich gelöscht');
    } catch (error) {
      console.error('Delete user error:', error);
      const errorMessage = error instanceof Error
        ? error.message
        : 'Beim Löschen des Benutzers ist ein unbekannter Fehler aufgetreten';
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const renderUserCard = (userData: User) => {
    const getProfileImageUrl = (url: string | null): string | undefined => {
      if (!url) return undefined;
      return url.startsWith('http') ? url : `http://localhost:5002${url}`;
    };

    return (
      <motion.div
        key={userData.id}
        whileHover={{ scale: 1.02 }}
        className="p-4 bg-white rounded-lg shadow-sm hover:shadow-md transition-all"
      >
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            {userData.profile_image_url ? (
              <img
                src={getProfileImageUrl(userData.profile_image_url)}
                alt={`${userData.username} Profilbild`}
                className="w-12 h-12 rounded-full object-cover mr-3"
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.style.display = 'none';
                  const parent = target.parentElement;
                  if (parent) {
                    const fallbackIcon = document.createElement('div');
                    fallbackIcon.className = `w-12 h-12 rounded-full mr-3 flex items-center justify-center ${
                      userData.role === 'ADMIN' ? 'text-[#FF8A4C]' : 'text-[#50697D]'
                    }`;

                    const iconContent = userData.role === 'ADMIN' ? (
                      '<svg class="w-12 h-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M7 11V7a5 5 0 0 1 10 0v4"></path><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect></svg>'
                    ) : (
                      '<svg class="w-12 h-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>'
                    );

                    fallbackIcon.innerHTML = iconContent;
                    parent.appendChild(fallbackIcon);
                  }
                }}
              />
            ) : (
              userData.role === 'ADMIN' ? (
                <Shield className="w-12 h-12 text-[#FF8A4C] mr-3" />
              ) : (
                <UserIcon className="w-12 h-12 text-[#50697D] mr-3" />
              )
            )}
            <div>
              <h3 className="font-semibold">{userData.username}</h3>
              <p className="text-sm text-gray-500">{userData.email}</p>
            </div>
          </div>
          {user?.role === 'ADMIN' && (
            <div className="flex items-center space-x-2">
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => handleEditUser(userData)}
                className="text-[#50697D] hover:text-[#3A4D5C]"
              >
                <Edit size={20} />
              </motion.button>
              {user.id !== userData.id && (
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => handleDeleteUser(userData.id)}
                  className="text-red-500 hover:text-red-700"
                >
                  <Trash2 size={20} />
                </motion.button>
              )}
            </div>
          )}
        </div>
        <div className="text-sm text-gray-500">
          <p>Rolle: {userData.role === 'ADMIN' ? 'Administrator' : 'Benutzer'}</p>
          <p>Erstellt am: {new Date(userData.created_at).toLocaleDateString()}</p>
        </div>
      </motion.div>
    );
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex-none bg-white rounded-t-lg shadow-lg">
        <div className="p-4 md:p-6">
          <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 mb-6">
            <h2 className="text-xl md:text-2xl font-bold flex items-center text-[rgb(58,79,102)]">
              <Users className="mr-2" />
              Benutzerverwaltung
            </h2>
            {user?.role === 'ADMIN' && (
              <button
                onClick={() => navigate('/users/create')}
                className="flex items-center justify-center px-4 py-2 bg-[#50697D] text-white rounded-lg hover:bg-[#3A4D5C] whitespace-nowrap"
              >
                <PlusCircle size={20} className="mr-2" />
                Neuer Benutzer
              </button>
            )}
          </div>

          {error && (
            <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg">
              {error}
            </div>
          )}
        </div>
      </div>

      <div className="flex-1 overflow-y-auto min-h-0 bg-white rounded-b-lg shadow-lg">
        <div className="p-4 md:p-6">
          {isLoading ? (
            <div className="flex justify-center items-center min-h-[200px]">
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ duration: 1, repeat: Infinity, ease: 'linear' }}
                className="rounded-full h-12 w-12 border-4 border-[#50697D] border-t-[#FF8A4C]"
              />
            </div>
          ) : (
            <div className="grid gap-4 sm:gap-6 md:grid-cols-2 lg:grid-cols-3">
              {users.map(renderUserCard)}
            </div>
          )}
        </div>
      </div>

      {editingUser && (
        <UserEditDialog
          user={editingUser}
          onClose={() => setEditingUser(null)}
          onSave={async (updateData) => {
            await handleUpdateUser(editingUser.id, updateData);
          }}
        />
      )}
    </div>
  );
};

export default UserManagement;
