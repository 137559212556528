// frontend/src/components/posts/PostItem.tsx
import React from 'react';
import { motion } from 'framer-motion';
import { Calendar, Building, ArrowRight, Trash2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../services/api';
import type { Post } from '../../services/api';

const API_BASE = 'http://localhost:5002';

interface PostItemProps {
 post: Post;
 onRefresh: () => Promise<void>;
}

export const PostItem: React.FC<PostItemProps> = ({ post, onRefresh }) => {
 const navigate = useNavigate();

 const handleDelete = async (e: React.MouseEvent) => {
   e.stopPropagation();  // Verhindert Navigation beim Löschen

   if (!window.confirm('Möchten Sie diesen Beitrag wirklich löschen?')) {
     return;
   }

   try {
     await apiService.posts.delete(post.id);
     await onRefresh(); // Aktualisiere die Liste
   } catch (error) {
     console.error('Fehler beim Löschen:', error);
     alert('Fehler beim Löschen des Beitrags');
   }
 };

 return (
   <motion.div
     whileHover={{ scale: 1.02, boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)' }}
     whileTap={{ scale: 0.98 }}
     className="bg-white rounded-lg shadow-lg overflow-hidden cursor-pointer transition-all duration-300"
     onClick={() => navigate(`/posts/${post.id}`)}
   >
     <div className="p-6">
       <div className="flex items-center justify-between mb-4">
         <div className="flex items-center flex-1">
           {post.company?.logo_url ? (
             <motion.img
               src={post.company.logo_url.startsWith('http') ?
                 post.company.logo_url :
                 `${API_BASE}${post.company.logo_url}`}
               alt={`${post.company.name} Logo`}
               className="w-8 h-8 rounded-full mr-3 flex-shrink-0 object-cover"
               whileHover={{ scale: 1.1 }}
               whileTap={{ scale: 0.9 }}
               onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                 const target = e.target as HTMLImageElement;
                 target.onerror = null;
                 target.style.display = 'none';
               }}
             />
           ) : (
             <motion.div
               className="w-8 h-8 bg-[rgb(58,79,102)] rounded-full mr-3 flex-shrink-0 flex items-center justify-center text-white text-sm font-bold"
               whileHover={{ scale: 1.1 }}
               whileTap={{ scale: 0.9 }}
             >
               {post.company?.name.charAt(0) || '?'}
             </motion.div>
           )}
           <h3 className="text-xl font-bold text-[rgb(58,79,102)] line-clamp-1">{post.title}</h3>
         </div>
         <motion.button
           whileHover={{ scale: 1.1 }}
           whileTap={{ scale: 0.9 }}
           onClick={handleDelete}
           className="p-2 text-red-500 hover:bg-red-50 rounded-lg transition-colors ml-4"
         >
           <Trash2 size={20} />
         </motion.button>
       </div>
       <p className="text-[rgb(83,103,123)] mb-4 line-clamp-3">{post.content}</p>
       <div className="flex items-center justify-between text-sm text-[rgb(83,103,123)]">
         <div className="flex items-center gap-4">
           <div className="flex items-center">
             <Calendar size={16} className="mr-1 text-[rgb(58,79,102)]" />
             <span>{new Date(post.created_at).toLocaleDateString()}</span>
           </div>
           <div className="flex items-center">
             <Building size={16} className="mr-1 text-[rgb(58,79,102)]" />
             <span>{post.company?.name}</span>
           </div>
         </div>
         <motion.div
           whileHover={{ x: 5 }}
           className="text-[rgb(255,138,76)] flex items-center"
         >
           <span className="mr-1">Mehr</span>
           <ArrowRight size={16} />
         </motion.div>
       </div>
     </div>
   </motion.div>
 );
};

export default PostItem;